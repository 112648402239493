import {
    useState
} from 'react'
import { func, string } from 'prop-types'
import AddAddressDialog from '../AddAddressDialog/AddAddressDialog'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const AddAddressButton = ({ size, setAddress, className }) => {
    const translations = useTranslation()
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true)

    return (
        <>
            <BaseButton
                size={size}
                type='button'
                onClick={handleShow}
                disableElevation
                className={className}
            >
                {translations('checkout:add.address.button')}
            </BaseButton>
            <AddAddressDialog isVisible={showModal} onClose={handleClose} setAddress={setAddress} />
        </>

    )
}

AddAddressButton.propTypes = {
    setAddress: func,
    size: string
}

AddAddressButton.defaultProps = {
    setAddress: undefined,
    size: undefined
}

export default AddAddressButton
