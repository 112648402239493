import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    styledTextArea: {
        width: '100%',
        border: '1px solid #858585',
        minHeight: '140px',
        resize: 'none',
        marginTop: theme.spacing(3),
        fontFamily: 'DM Sans',
        fontSize: '16px',
        '&:focus-visible': {
            outline: 'none'
        }
    }
}))