import { Paper, Typography } from '@material-ui/core'
import {
    bool, func, object, string
} from 'prop-types'
import clsx from 'clsx'
import useStyles from './style'
import Details from './Details/Details'

const Location = ({
    place, onClick, activePlaceId, isLoadingPlaceDetail, placeDetail
}) => {
    const classes = useStyles()
    const address = place.formatted_address.split(',')

    const active = activePlaceId === place.place_id

    return (
        <Paper
            onClick={onClick}
            variant='outlined'
            className={clsx(classes.location, { [classes.active]: active })}
        >
            <Typography
                variant='h6'
                gutterBottom
            >
                { place.name }
            </Typography>
            {address.map((part) => (
                <Typography
                    key={part}
                    variant='body2'
                >
                    { part }
                </Typography>
            ))}
            {active && (
                <Details
                    key={place.place_id}
                    isLoading={isLoadingPlaceDetail}
                    placeDetails={placeDetail}
                />
            )}
        </Paper>
    )
}

Location.propTypes = {
    place: object.isRequired,
    onClick: func.isRequired,
    activePlaceId: string,
    isLoadingPlaceDetail: bool.isRequired,
    placeDetail: object
}

Location.defaultProps = {
    placeDetail: null,
    activePlaceId: null
}

export default Location
