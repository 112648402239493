import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    logoContainer: {
        width: '100%',
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    },
    congratulations: {
        background: '#f8f8f8',
        textAlign: 'center',
        padding: theme.spacing(6, 6)
    },
    customStepper: {
        background: '#f8f8f8',
        [theme.breakpoints.down('lg')]: {
            overflow: 'hidden'
        },
        '& .MuiStepLabel-label': {
            [theme.breakpoints.down('lg')]: {
                whiteSpace: 'nowrap'
            },
        },
        '& .MuiStepIcon-root': {
            width: '2em',
            height: '2em',
            [theme.breakpoints.down('lg')]: {
                width: '1em',
                height: '1em',
            },
        },
        '& .MuiStepConnector-alternativeLabel': {
            top: '24px',
            left: 'calc(-50% + 45px)',
            right: 'calc(50% + 45px)',
        },
        '& .MuiStepConnector-lineHorizontal': {
            [theme.breakpoints.down('md')]: {
                width: '25px'
            },
        }
    },
    fontWeightBold: {
        fontWeight: 'bold'
    },
    addressesReview: {
        backgroundColor: 'transparent !important',
        border: 'none !important',
        padding: '0 !important',
        margin: '0 !important',
        minHeight: '170px !important'
    },
    data: {
        padding: theme.spacing(2)
    },
    customStepperOrder: {
        '& .MuiStepLabel-iconContainer': {
            width: '50px',
            height: '50px',
            background: '#CCCCCC',
            borderRadius: '50%',
            alignItems: 'center',
            paddingLeft: '13px',
            textAlign: 'center',
            margin: '0 auto',
            color: 'white',
        },
        '& .MuiStepConnector-alternativeLabel': {
            top: '24px',
            left: 'calc(-50% + 35px)',
            right: 'calc(50% + 35px)',
            position: 'absolute',
        },
        '& .active-order': {
            '& .MuiStepLabel-iconContainer': {
                background: 'black'
            }
        }
    },
    stepperOrderContainer: {
        textAlign: 'center',
        padding: theme.spacing(8, 0, 2, 0),
        borderBottom: '1px solid #E7E7E7',
    },
    thankyouPageContainer: {
        maxWidth: theme.breakpoints.values.md,
        margin: theme.spacing(0, 'auto')
    },
    thankyouPageBox: {
        padding: theme.spacing(3, 0, 2, 0),
        borderBottom: '1px solid #E7E7E7',
    },
    decimalList: {
        listStyleType: 'decimal',
        paddingInlineStart: theme.spacing(3)
    }
}))