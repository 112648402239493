import Typography from '@material-ui/core/Typography'
import { Card } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const SubtotalCard = ({
    totalListPrice, className
}) => {
    const classes = useStyles()
    const translations = useTranslation()

    return (
        <Card className={clsx(classes.summaryContainer, className)} elevation={0}>
            <div>
                <Typography variant='body1' className={classes.summarySubtotal}>
                    {translations('checkout:subtotal')}
                </Typography>
                {/* {discounts.length > 0 && (
                    <Typography variant='body1' className={classes.summarySubtotal}>
                        {translations('checkout:discount.label')}
                    </Typography>
                )} */}

                {/* SHIPPING */}
                {/* <Typography variant='body1' style={{ wordBreak: 'nowrap' }}>
                    {translations('checkout:shipping')}
                </Typography> */}
            </div>
            <div className={classes.summaryPrices}>
                <Typography variant='subtitle2' className={classes.grandTotal}>
                    { totalListPrice?.displayValue }
                </Typography>

                {/* {discounts.length > 0  && (
                    <Typography variant='subtitle2' className={classes.summarySubtotal}>
                        {totalPurchasePrice.currency}
                        {' '}
                        {(totalPurchasePrice?.amount - totalListPrice?.amount - (shipAmount?.amount ?? 0)).toFixed(2)}
                    </Typography>
                )} */}

                {/* SHIPPING */}
                {/* {shipAmount?.displayValue || (discounts.length === 0 && totalPurchasePrice.amount !== totalListPrice.amount)
                    ? (
                        <Typography variant='subtitle2'>
                            { shipAmount?.displayValue ?? `${totalPurchasePrice?.currency} ${(totalPurchasePrice.amount - totalListPrice.amount).toFixed(2)} `}
                        </Typography>
                    )
                    : (
                        <Typography variant='body1'>
                            {translations('checkout:shipping.cost.requirement')}
                        </Typography>
                    )
                } */}
            </div>
        </Card>
    )
}

SubtotalCard.propTypes = {
    totalListPrice: PropTypes.object,
    className: PropTypes.string
}

SubtotalCard.defaultProps = {
    totalListPrice: {},
    className: undefined
}

export default SubtotalCard
