import { parseCookies, setCookie } from 'nookies'
import axios from 'axios'
import { initialize } from '@bloomreach/spa-sdk'
import getConfig from 'next/config'
import { COOKIE_LANGUAGE, COOKIE_LOCATION, COOKIE_REGION } from '../../components/_Mappings/cookies'
import regionLocales from '../../components/_Mappings/regionLocales'
import { getSSRResourceBundles } from '../../components/_Elements/ResourceBundles/ResourceBundles'
import {
    getFacetFilterFromUrl,
    getSmViewId
} from '../utils'
import storeKeys from '../../components/_Mappings/storeKeys'
import channelKeys from '../../components/_Mappings/channelKeys'
import useChannelKeyWorkaround from '../../components/_Modules/Cart/useChannelKeyWorkaround'
import { itemsByCategory, searchItems } from './query'

const { publicRuntimeConfig } = getConfig()
    
   

const getInitialProps = async (ctx) => {
    const location = parseCookies({ req: ctx.req })[COOKIE_LOCATION] ?? 'US'
    const shippingRegion = parseCookies({ req: ctx.req })[COOKIE_REGION] ?? ''
    let language = parseCookies({ req: ctx.req })[COOKIE_LANGUAGE] 
   
    if (!language && location === 'IT') {
        language = 'it'
        
    } else if (!language && location === 'FR') {
        language = 'fr'
        
    } else if (!language && location === 'RU') {
        language = 'ru'
        
    } else if (!language && location !== 'IT' && location !== 'RU' && location !== 'FR') {
        language = 'en'
        
    }
   

    const {
        path,
        ...queryParams
    } = ctx.query

    // Hosted Checkout Return URL handling
    if (queryParams.hostedCheckoutId && queryParams.orderId && path.includes('thankyoupage')) {
        const checkoutStep = `checkout?checkoutStep=2`
        try {
            const result = await axios.post(`${publicRuntimeConfig.cmsRestserviceUrl}/payment/validate`, {
                hostedCheckoutId: queryParams.hostedCheckoutId,
                orderId: queryParams.orderId,
                region: queryParams.region,
                guestCheckout: queryParams.guestCheckout !== 'undefined' ? queryParams.guestCheckout : false,
                locale: language
            })

            if (ctx.res && result.data.state !== 'PAID') {
                const languageParam = language !== 'en' ? `/${language}` : ''
                const guestCheckoutParam = result.data.guestCheckout ? '&guestCheckout=true' : '&guestCheckout=false'
                ctx.res.writeHead(302, {
                    Location: `${languageParam}/${checkoutStep}&status=${result.data.state}${guestCheckoutParam}`
                }).end()
            } else if (result.data.paymentName){
                queryParams.paymentName = result.data.paymentName
            }
        } catch (e) {
            console.error(e)
        }
    }

    const pathString = path ? `/${path.join('/')}` : ''
    const queryArr = Object.entries(queryParams)
    const queryString = queryArr.length > 0
        ? Object.entries(queryParams)
            .reduce((str, [key, value], i) => `${str + (i === 0 ? '?' : '&')}${key}=${value}`, '')
        : ''

    const configuration = {
        endpoint: ctx.locale === 'en'
            ? publicRuntimeConfig.pmaUrl
            : `${publicRuntimeConfig.pmaUrl.replace('resourceapi', '')}${ctx.locale}/resourceapi`,
        cmsRestserviceUrl: publicRuntimeConfig.cmsRestserviceUrl,
        graphqlUrl: publicRuntimeConfig.graphqlUrl,
        request: { path: pathString + queryString }
    }

    // send shippingregion custom header for targeting in bloomreach
    axios.defaults.headers.common['X-shipping-region'] = shippingRegion

    const page = await initialize({
        ...configuration,
        httpClient: axios,
        request: {
            ...configuration.request,
            connection: ctx.req?.connection
        }
    })

    if(page.model?.meta?.visitor?.id){
        setCookie({res: ctx.res}, '_visitor', page.model.meta.visitor.id, {
            maxAge: 2592000, path: '/', sameSite: 'None', secure: true
        })
    }
    // Check if cookie location differs from the location in URL
    if (!page.isPreview() && ctx.res && language && ctx.locale !== language) {
        const pathArray = ctx.asPath.split('?')
        if (pathArray[0] === '/'){
            ctx.res.writeHead(302, {
                Location: language !== 'en' ? `/${language}${ctx.asPath}` : `${ctx.asPath}`
            }).end()
        } else {
            
            const languageMap = await axios.post(`${publicRuntimeConfig.cmsRestserviceUrl}/multilanguage/pages`, {
                path: pathArray[0],
                language: ctx.locale
            })
            const href = Object.entries(languageMap.data).find(value => value[0] === language)
            if (href !== undefined){
                const query = pathArray.length > 1 ? '?' + pathArray[1] : ''
                ctx.res.writeHead(302, {
                    Location: language !== 'en' ? `/${language}${href[1]}${query}` : `${href[1]}${query}`
                }).end()
            }
            else {
                language = ctx.locale
                setCookie({res: ctx.res}, COOKIE_LANGUAGE, ctx.locale, {
                    maxAge: 2592000, path: '/', sameSite: 'None', secure: true
                })
            }   
        }
    }

    const initialResourceBundles = await getSSRResourceBundles(
        page,
        axios,
        language,
        publicRuntimeConfig.cmsRestserviceUrl
    )

    let componentId
    let categoryId
    let tag
    let pageSize
    let customSort
    const pageComponents = page?.model?.page
    if(pageComponents){
        Object.entries(pageComponents).forEach(elem => {
            if (elem[1]?.name == 'pagenotfound'){
                ctx.res.statusCode = 404
            }
            if(elem[1]?.name?.includes('ProductCategoryGrid') || elem[1]?.name?.includes('ProductGridSearch') || elem[1]?.name?.includes('ProductTeaserGrid')){
                componentId = elem[1].id
                categoryId = elem[1].meta?.paramsInfo?.categoryId
                tag = elem[1].meta?.paramsInfo?.tag
                pageSize = elem[1].meta?.paramsInfo?.pageSize || 12
                customSort = elem[1].meta?.paramsInfo?.customSorting ? '-variants.attributes.' + elem[1].meta.paramsInfo.customSorting : '-variants.attributes.sort-order'
            }
        })
    }
 
    let products = {}
    let filters = {}
    const sort = /sort=-*\w+/.exec(configuration.request.path)?.[0]?.replace('sort=', '') || '';
    const possibleSortings = ['listPrice', '-listPrice']
    const sortFields = sort && possibleSortings.includes(sort) ? sort : customSort
    const countclickloadmore = /page=\d+/.exec(configuration.request.path)?.[0] || '';
    const offsetMultiplier = Number(/\d+/.exec(countclickloadmore)?.[0]) || 1
    const currentFilters = getFacetFilterFromUrl(componentId, ctx.query)
    const searchText =  tag ? tag : /search=\w+/.exec(configuration.request.path)?.[0]?.replace('search=', '').toLowerCase() || '';


    if(categoryId || searchText){
        const smViewId = getSmViewId(page)
        const { storeKey, currency } = storeKeys[location] || {}
        const distributionChannelWorkaround = useChannelKeyWorkaround(channelKeys[storeKey])

        const defaultfacetFieldFilters = {
            id: 'variants.scopedPrice',
            values: ['exists']
        }

        axios.defaults.withCredentials = true
        const res = await axios.post(publicRuntimeConfig.graphqlUrl + '/signin', {
            country: location,
            currency: currency,
            attrs: {
                channel: regionLocales[location]
            }
        },
        {
            headers: {
                'Accept-Language': regionLocales[language.toUpperCase()],
                'Content-Type':'application/json;charset=UTF-8',
                'connector': 'commercetools'
            }
        })

        const params = [
            {
                name: 'priceCurrency',
                values: [currency]
            },
            {
                name: 'priceChannel',
                values: [distributionChannelWorkaround]
            },
            {
                name: 'priceCountry',
                values: [location]
            }
        ]

        const facet = {
            name: 'facet',
            values: [
                'variants.attributes.size',
                'variants.attributes.colour-1-hex',
                'variants.attributes.colour-2-hex'
            ]
        }

        const getQuery = () => {
            if (categoryId) {
                return {
                    query: itemsByCategory,
                    operationName: 'ItemsByCategory',
                    //result: 'findItemsByCategory'
                }
            } else if (searchText) {
                return {
                    query: searchItems,
                    operationName: 'Items',
                    //result: 'findItemsByKeyword'

                }
            }
        }

        const graphqlQuery = {
            ...getQuery(),
            variables: {
                categoryId,
                limit: pageSize,
                offset: pageSize * (offsetMultiplier - 1),
                sortFields: sortFields,
                query: searchText,
                queryHint: {
                    viewId: smViewId,
                    storeKey,
                    facetFieldFilters: [defaultfacetFieldFilters, ...currentFilters],
                    params: [...params],
                    customVariantAttrFields: tag ? ['tag'] : []
                }
            }
        }

        const response  = await axios({
            url: publicRuntimeConfig.graphqlUrl + '/graphql',
            method: 'post',
            headers:{
                'Accept-Language': regionLocales[language.toUpperCase()],
                'Content-Type':'application/json;charset=UTF-8',
                'connector': 'commercetools',
                authorization: 'Bearer ' + res?.data?.authorization
            },
            data: graphqlQuery,
            withCredentials: false 
        })

        const filterQuery = {
            ...getQuery(),
            variables: {
                categoryId,
                limit: 0,
                offset: 0,
                sortFields: sortFields,
                query: searchText,
                queryHint: {
                    viewId: smViewId,
                    storeKey,
                    facetFieldFilters: [defaultfacetFieldFilters],
                    params: [...params, facet],
                    customVariantAttrFields: tag ? ['tag'] : []
                }
            }
        }
        
        const filterResponse = await axios({
            url: publicRuntimeConfig.graphqlUrl + '/graphql',
            method: 'post',
            headers:{
                'Accept-Language': regionLocales[language.toUpperCase()],
                'Content-Type':'application/json;charset=UTF-8',
                'connector': 'commercetools',
                authorization: 'Bearer ' + res?.data?.authorization
            },
            data: filterQuery,
            withCredentials: false
        })
        products = {queryResult: categoryId ? response.data.data.findItemsByCategory : response.data.data.findItemsByKeyword}
        filters = {queryResult: categoryId ? filterResponse.data.data.findItemsByCategory : filterResponse.data.data.findItemsByKeyword}
    }
    if (ctx.res && !ctx.res.finished) {
        ctx.res.setHeader('Content.js-Security-Policy', `frame-ancestors 'self' ${new URL(configuration.endpoint).host}`)
        // ctx?.res?.setHeader('Access-Control-Allow-Origin', '*')
        // ctx?.res?.setHeader('Access-Control-Allow-Headers', '*')
        // ctx?.res?.setHeader('Content.js-Security-Policy', 'frame-ancestors *')
        // ctx?.res?.setHeader('X-Frame-Options', 'ALLOWALL')
    }

    return {
        configuration,
        cmsBaseUrl: publicRuntimeConfig.cmsBaseUrl,
        googleAnalyticsEnvironment: publicRuntimeConfig.googleAnalyticsEnvironment,
        page,
        initialResourceBundles,
        products,
        sortFields,
        filters,
        currentFilters,
        pagination: offsetMultiplier
    }
}

export default getInitialProps
