import React, { useContext } from "react";
import { Box, Card, CardContent, CardMedia, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import useStyles from '../SideSummaryCart/style'
import { useCart } from "@bloomreach/connector-components-react";
import LoginContext from "../../../../Account/Login/LoginContext";
import CheckOutContext from "../../../CheckOutContext";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobileOrTablet } from "../../../../../../lib/utils";
import Mastercard from "../../../../../../public/paymentMethod/Mastercard.svg"
import Amex from "../../../../../../public/paymentMethod/Amex.svg"
import DinersClub from "../../../../../../public/paymentMethod/DinersClub.svg"
import Jcb from "../../../../../../public/paymentMethod/JCB.svg"
import Maestro from "../../../../../../public/paymentMethod/Maestro.svg"
import Visa from "../../../../../../public/paymentMethod/Visa.svg"
import useTranslation from "../../../../../_Elements/ResourceBundles/useTranslation";

const SideSummaryCart = ({order}) => {

    const { cartId } = useContext(LoginContext)
    const { orderDraft } = useContext(CheckOutContext)
    const [cart] = useCart({ cartId });
    const translations = useTranslation()

    const classes = useStyles()

    return (
        <>
            {isMobileOrTablet() ? 
                (<Box className={classes.customAccordion}>
                    <Accordion className={classes.sideSummaryCartMobile}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            {translations('checkout:order.summary')}
                        </AccordionSummary>

                        <AccordionDetails className={classes.accordionDetails}>
                            {order ? (<SummaryOrderContent classes={classes} order={order}/>) : (<SummaryContent classes={classes} cart={cart} orderDraft={orderDraft}/>)}
                        </AccordionDetails>

                    </Accordion>
                </Box>) : 
                (<Box className={classes.sideSummaryCart}>

                    {order ? (<SummaryOrderContent classes={classes} order={order}/>) : (<SummaryContent classes={classes} cart={cart} orderDraft={orderDraft}/>)}
                    
                    {!order && (
                        <>
                            <Accordion className={classes.accordionSideSummary}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <div className={classes.totalText}>
                                        {translations('checkout:order.summary.payment')}
                                    </div>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <div>
                                        {translations('checkout:order.summary.payment.text')}
                                    </div>

                                    <Mastercard />
                                    <Amex />
                                    <DinersClub />
                                    <Jcb />
                                    <Maestro />
                                    <Visa />
                                    
                                </AccordionDetails>

                            </Accordion>
                            <Accordion className={classes.accordionSideSummary}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <div className={classes.totalText}>
                                        {translations('checkout:order.summary.help')}
                                    </div>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {translations('checkout:order.summary.help.text')}
                                </AccordionDetails>

                            </Accordion>
                        </>

                    )}
                </Box>)
            }
        </>
    )
}

const SummaryContent = ({classes, cart, orderDraft}) => {
    const translations = useTranslation()
    return(
        <>
            <Box className={classes.boxContainer}>

            {console.log("orderDraft", orderDraft, cart)}

                <Typography variant="h5" align="center" className={classes.titleSummary}>{translations('checkout:order.summary')}</Typography>

                {cart?.entries?.map((entry, index) => {
                    return (
                        <div key={index} className={classes.customCard}>
                            {entry.items.map((item, index) => {
                                let size = item.varAttrs.filter((attr) => {attr.name === "size"})
                                return (
                                    <Card key={index} className={classes.root}>
                                        <CardMedia 
                                            component={'img'}
                                            className={classes.cover}
                                            image={`/_next/image?url=${encodeURI(item.imageSet?.thumbnail?.link.href)}&w=768&q=90`}
                                        />

                                        <div className={classes.details}>

                                            <CardContent className={classes.content}>
                                                <Typography component={"h5"}>
                                                    {item.displayName}
                                                </Typography>
                                                {size.length > 0 ? 
                                                    (<Typography variant="body1" component={"div"}>
                                                        {translations('checkout:order.size')}: {size}
                                                    </Typography>) : ""
                                                }
                                                {entry.quantity > 0 ?
                                                    (<Typography variant="body1" component={"div"}>
                                                        {translations('checkout:order.quantity')}: {entry.quantity}
                                                    </Typography>) : ""
                                                }
                                                
                                            </CardContent>

                                        </div>

                                    </Card>
                                )
                            })}
                        </div>
                    )
                })}
            </Box>

            <Box className={classes.boxContainer}>
                <Typography variant="body1" component={"div"}>
                    {translations('checkout:order.subtotal')}: <Typography variant="body1" className={classes.floatRight}>{cart?.totalListPrice?.moneyAmounts[0]?.displayValue}</Typography>
                </Typography>

                {orderDraft?.shipAmount ? 
                    (
                    <Typography variant="body1" component={"div"}>
                        {translations('checkout:order.shippingFee')}: <Typography variant="body1" className={classes.floatRight}>{orderDraft?.shipAmount.amount === 0 ? "Gratis" : orderDraft.shipAmount.displayValue}</Typography>
                    </Typography>
                    ) : ""}

                {cart?.discountCodes.map((discount) => {
                    return(
                        <Typography variant="body1" component={"div"}>
                            {translations('checkout:order.discountCode')}: <Typography variant="body1" className={classes.floatRight}>{discount?.code}</Typography>
                        </Typography>
                    )
                })}

                {/* {orderDraft?.shippingAmount ? 
                    (
                    <Typography variant="body1" component={"div"}>
                        Confezione regalo: <Typography variant="body1" className={classes.floatRight}>{orderDraft?.shippingAmount}</Typography>
                    </Typography>
                    ) : ""} */}
            </Box>

            {/* {orderDraft?.shippingAmount ? 
                (
                <Box className={classes.boxContainer}>
                    <Typography variant="body1" component={"div"}>
                        <Typography variant="body1" className={classes.floatRight}>{orderDraft?.shippingAmount}</Typography>
                    </Typography>
                </Box>
            ) : ""} */}

            <Box className={classes.boxContainer}>
                <div className={classes.totalText}>
                    {translations('checkout:order.total')}: <span className={classes.floatRight}>{cart?.totalPurchasePrice?.moneyAmounts[0]?.displayValue}</span>
                </div>
            </Box>

        </>
    )
}

const SummaryOrderContent = ({classes, order}) => {
    const translations = useTranslation()
    let partialSum = 0
    return(
        <>
            <Box className={classes.boxContainer}>

                <Typography variant="h5" align="center" className={classes.titleSummary}>{translations('checkout:order.summary')}</Typography>

                {order?.orderItems?.map((entry, index) => {
                    console.log("entry", entry)
                    let size = entry.item.varAttrs.filter((attr) => {attr.name === "size"})
                    partialSum = entry.unitPrice.moneyAmounts.map((money) => money.amount).reduce((prev, next) => prev + next)
                    return (
                        <div key={index} className={classes.customCard}>
                            <Card key={index} className={classes.root}>
                                <CardMedia 
                                    component={'img'}
                                    className={classes.cover}
                                    image={`/_next/image?url=${encodeURI(entry.item.imageSet?.thumbnail?.link.href)}&w=768&q=90`}
                                />

                                <div className={classes.details}>

                                    <CardContent className={classes.content}>
                                        <Typography component={"h5"}>
                                            {entry.item.displayName}
                                        </Typography>
                                        {size.length > 0 ? 
                                            (<Typography variant="body1" component={"div"}>
                                                {translations('checkout:order.size')}: {size}
                                            </Typography>) : ""
                                        }
                                        {entry.quantity > 0 ?
                                            (<Typography variant="body1" component={"div"}>
                                                {translations('checkout:order.quantity')}: {entry.quantity}
                                            </Typography>) : ""
                                        }
                                        
                                    </CardContent>

                                </div>
                            </Card>
                        </div>
                    )
                })}
            </Box>

            <Box className={classes.boxContainer}>
                <Typography variant="body1" component={"div"}>
                    {translations('checkout:order.subtotal')}: <Typography variant="body1" className={classes.floatRight}>{partialSum.toFixed(2)}</Typography>
                </Typography>

                {order?.shipAmount ? 
                    (
                    <Typography variant="body1" component={"div"}>
                        {translations('checkout:order.shippingFee')}: <Typography variant="body1" className={classes.floatRight}>{order?.shipAmount.amount === 0 ? "Gratis" : order.shipAmount.displayValue}</Typography>
                    </Typography>
                    ) : ""}

                {order?.discountCodes.map((discount) => {
                    return(
                        <Typography variant="body1" component={"div"}>
                            {translations('checkout:order.discountCode')}: <Typography variant="body1" className={classes.floatRight}>{discount?.code}</Typography>
                        </Typography>
                    )
                })}

                {/* {orderDraft?.shippingAmount ? 
                    (
                    <Typography variant="body1" component={"div"}>
                        Confezione regalo: <Typography variant="body1" className={classes.floatRight}>{orderDraft?.shippingAmount}</Typography>
                    </Typography>
                    ) : ""} */}
            </Box>

            {/* {orderDraft?.shippingAmount ? 
                (
                <Box className={classes.boxContainer}>
                    <Typography variant="body1" component={"div"}>
                        <Typography variant="body1" className={classes.floatRight}>{orderDraft?.shippingAmount}</Typography>
                    </Typography>
                </Box>
            ) : ""} */}

            <Box className={classes.boxContainer}>
                <div className={classes.totalText}>
                    {translations('checkout:order.total')}: <span className={classes.floatRight}>{order?.totalAmount[0]?.displayValue}</span>
                </div>
            </Box>

        </>
    )
}

export default SideSummaryCart