import makeStyles from '@material-ui/styles/makeStyles'
import router from 'next/router'

export default makeStyles((theme) => ({
    // StepContent
    header: {
        maxWidth: theme.breakpoints.values.md,
        alignItems: 'center',
        flexDirection: 'column',
        margin: theme.spacing(4, 'auto')
    },
    payment: {
        textAlign: 'center'
    },
    addAddressContainer: {
        maxWidth: theme.breakpoints.values.sm,
        margin: theme.spacing(4, 'auto')
    },
    // AdressSelection
    loadingOverlay: {
        zIndex: 100,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    checkboxContainer: {
        [theme.breakpoints.up('md')]: {
            minHeight: 200,
            marginBottom: theme.spacing(),
            marginTop: theme.spacing()
        }
    },
    // AdressSelection & Review
    addressesContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: theme.spacing(0, 'auto'),
    },
    reviewAddressesContainer: {
        maxWidth: '900px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: theme.spacing(0, 'auto'),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    // Review
    addresses: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    itemList: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    summaryContainer: {
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center'
        }
    },
    shippingBilligContainer: {
        maxWidth: theme.breakpoints.values.md,
        margin: '0 auto',
        '& .MuiTypography-h6': {
            fontWeight: 600
        },
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('lg')]: {
            '& .MuiGrid-item': {
                padding: theme.spacing(2)
            }
        }
    },
    addressesReview: {
        backgroundColor: 'transparent !important',
        border: 'none !important',
        padding: '0 !important',
        margin: '0 !important',
        minHeight: '170px !important'
    },
    reviewSummaryContainer: {
        width: '100% !important',
        background: 'transparent',
        borderBottom: '1px solid #E7E7E7',
        borderRadius: 0,
        padding: '24px 0',
    },
    fontWeightBold: {
        fontWeight: '800'
    },
    textAlignRight: {
        textAlign: 'right'
    },
    conditionsSale: {
        width: '100%',
        padding: theme.spacing(3, 0)
    },
    summaryReview: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing(3, 0),
        borderBottom: '1px solid #E7E7E7',
        '& .MuiTypography-body1:not(:first-child)': {
            paddingTop: theme.spacing(1)
        },
        '& .MuiTypography-subtitle2:not(:first-child)': {
            paddingTop: theme.spacing(1)
        }
    },
    buttonCheckout: {
        background: 'transparent',
        color: 'black',
        textDecoration: 'underline',
        fontWeight: '600',
        '&:hover': {
            background: 'transparent',
            color: 'black',
            textDecoration: 'underline',
            fontWeight: '600',
            '-webkit-text-stroke': '0px black'
        }
    },
    // containerSelectedAddresses: {
    //     [theme.breakpoints.down('lg')]: {
    //         height: '100%'
    //     },
    // },
    discountReview: {
        width: '100% !important'
    },
    titleStyle: {
        fontWeight: 'bold',
        letterSpacing: '0.1rem'
    }
}
))
