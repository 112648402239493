import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles(() => ({
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    buttonCheckout: {
        background: 'transparent',
        color: 'black',
        textDecoration: 'underline',
        fontWeight: '600',
        '&:hover': {
            background: 'transparent',
            color: 'black',
            textDecoration: 'underline',
            fontWeight: '600',
            '-webkit-text-stroke': '0px black'
        }
    }
}))
