import PropTypes from 'prop-types'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, IconButton, RadioGroup
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useEffect, useState } from 'react'
import SizeButton from './components/SizeButton'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import Link from '../../../_Elements/Link'
import { sizeTranslation, skuToSizeObject } from '../../../../lib/utils'
import { Close } from '@material-ui/icons'

const ProductSize = ({ onChange, value, sizeList, dmodaArt, location, entriesWithContent, sku }) => {
    const translations = useTranslation()
    const classes = useStyles()

    const [showModal, setShowModal] = useState(false)

    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true)

    useEffect(() => {
        if (!value && sizeList) {
            const firstAvailableSize = sizeList.find(({ quantity }) => quantity)
            if (firstAvailableSize) {
                onChange(firstAvailableSize.size)
            }
        }
    }, [value, sizeList])
    const notEmptySizeArr = sizeList.filter((items) => items.size.length > 0)
    return (
        <>
            {notEmptySizeArr.length > 0
            && (
                <>
                <Typography
                    variant='subtitle1'
                    align='center'
                    component='p' 
                >
                    {translations('product:select.size')}
                </Typography>
                <div>
                    <Link
                        align='center'
                        secondary
                        
                        onClick={handleShow}
                        href={'#'}
                    >
                        {translations('product:sizeGuide')}
                    </Link>
                    {/* <Link
                    secondary
                    href='#'
                >
                    {translations('product:sizeSoldOut')}
                </Link> */}
                </div>
                <div>
                    <Typography
                        className={classes.smallText}
                    >
                    {
                        ['GB','US','FR'].includes(location) ? translations('product:size.description.' + location) : translations('product:size.description.IT')
                    }
                    </Typography>
                </div>
                </>
            )}

            <FormControl component='fieldset' className={classes.formControl}>
                <RadioGroup
                    name='size'
                    onChange={(event) => {
                        event.persist()
                        onChange(event.target.value)
                    }}
                    row
                    className={classes.radioGroup}
                >
                    {sizeList?.map(({ size, quantity }) => {
                        const isDisabled = quantity === 0 || !quantity
                        const label = sizeTranslation(size, dmodaArt, location)
                        return (
                            <FormControlLabel
                                className={classes.formControlLabel}
                                key={size}
                                value={size}
                                label={label}
                                control={(
                                    <SizeButton
                                        size={label}
                                        disabled={isDisabled}
                                        checked={String(size) === String(value?._mappedAttributes?.size)}
                                    />
                                )}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>

            <Dialog open={showModal} onClose={handleClose} aria-labelledby='form-dialog-title'
                fullWidth={true} maxWidth={'md'}>
                <DialogTitle disableTypography id='form-dialog-title' className={classes.dialogTitle}>
                    <Typography variant='h2' align='center'>
                        {translations('product:sizeGuide')}
                    </Typography>
                    <IconButton aria-label='close' onClick={handleClose} color='primary'>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    { Object.entries(entriesWithContent).map(([folder, faqList]) => faqList.length > 0 && (
                        <div
                            key={folder}
                            className={classes.faq}
                        >

                        {Object.entries(skuToSizeObject).map(([initial, name]) => {
                            console.log(initial, name);
                            if (sku.startsWith(initial)) {
                                return name.map((documentName) => {
                                    return faqList.filter((faq) => faq.name === documentName).map((el) => {
                                        return (
                                            <div key={el.name}>
                                                <Typography variant='h4' align='center'>{el.displayName}</Typography>
                                                <div dangerouslySetInnerHTML={{ __html: el.answerField.value }} className={classes.sizeTable} />
                                            </div>
                                        );
                                    });
                                });
                            }
                            return null;
                        })}


                        </div>
                    ))}
                </DialogContent>
            </Dialog>

        </>
    )
}

ProductSize.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    sizeList: PropTypes.array,
    dmodaArt: PropTypes.string,
    location: PropTypes.string,
}

ProductSize.defaultProps = {
    onChange: undefined,
    value: null,
    sizeList: [],
    dmodaArt: undefined,
    location: 'IT'
}

export default ProductSize
