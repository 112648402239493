import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    faq: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    mobileOverflow: {
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch'
    },
    thankyouPageContainer: {
        maxWidth: theme.breakpoints.values.md,
        margin: theme.spacing(0, 'auto'),
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        '& .MuiAccordionSummary-root': {
            borderBottom: '1px solid #858585'
        },
        '& .MuiAccordion-root:before': {
            position: 'unset',
        },
        '& .MuiTypography-body1': {
            fontWeight: 'bold',
            padding: theme.spacing(2, 0)
        }
    },
    fakeSideSummaryCart: {
        background: '#f1f1f1'
    },
}))
