import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    loadingOverlay: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    addressDataContainer: {
        flexGrow: 1
    },
    addressActionsContainer: {
        display: 'flex',
        flexDirection: 'column',

        '& > *:not(:last-child)': {
            marginBottom: theme.spacing()
        }
    },
    addressRadioContainer: {
        marginRight: theme.spacing(2.5)
    },
    addressContent: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1
    },
    addressContainer: ({
        isSelectable, active
    }) => ({
        display: 'flex',
        alignItems: 'center',
        minHeight: 200,
        position: 'relative',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto',
        margin: theme.spacing(1, 0),
        padding: theme.spacing(3, 2),
        backgroundColor: isSelectable ? 'transparent' : theme.palette.imgOverlay.main,
        borderRadius: 0,
        border: `1px solid ${theme.palette.imgOverlay.main}`,
        cursor: isSelectable && !active ? 'pointer' : 'inherit',
        '& .MuiTypography-body1': {
            padding: '8px 0'
        },

        [!active && isSelectable && '&:hover']: {
            backgroundColor: theme.palette.imgOverlay.main
        }
    }),
    // fontWeightAddress: {
    //     fontWeight: 600
    // }
}))
