import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    container: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    loadingOverlay: {
        zIndex: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    mapContainer: {
        position: 'relative',
        paddingBottom: '56.25%',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3)
        }
    },
    searchContainer: {
        position: 'relative',
        flexGrow: 0,
        marginBottom: theme.spacing(4),

        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(4)
        }
    },
    places: {
        position: 'relative',
        overflow: 'auto',
        borderRadius: theme.shape.borderRadius,
        overscrollBehavior: 'contain',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(4)
        },
        [theme.breakpoints.down('sm')]: {
            flexBasis: 'auto'
        }
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        borderRadius: '10px',
        width: '100%',
        height: '2px'
    },
    linearProgressTop: {
        bottom: 'auto',
        top: 0
    },
    infoWindowText: {
        paddingBottom: theme.spacing(3),
        textAlign: 'center'
    },
    fontWeightBold: {
        fontWeight: 'bold'
    }
}))
