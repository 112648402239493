import { useSignup } from '@bloomreach/connector-components-react'
import React, { useContext, useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'
import { Grid, Item, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import LoginContext from './LoginContext'
import { LoyaltyPointsContext } from '../LoyaltyPoints/LoyaltyPointsContext'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import synchronizeLoyaltyPoints from '../LoyaltyPoints/synchronizeLoyaltyPoints'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import { GoogleEnhancedEcommerce } from '../../ThirdPartyScripts/GoogleEnhancedEcommerce'
import { SFMCCollectJs } from '../../ThirdPartyScripts/SFMCCollectJs'
import { RETURN_URL_UNLOGGED_CHECKOUT } from '../../../_Mappings/urlPart'
import { COOKIE_LOCATION, COOKIE_LANGUAGE } from '../../../_Mappings/cookies'
import regionLocales from '../../../_Mappings/regionLocales'
import { FIREBASE_CONFIG, setTokenInSession } from '../../../../lib/utils'
import { initializeApp } from "firebase/app";
import storeKeys from '../../../_Mappings/storeKeys'
import axios from 'axios'
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth"
import getConfig from 'next/config'
import UserFeedbackDialog from '../UserFeedbackDialog/UserFeedbackDialog'
import { Google, Facebook, Apple } from 'react-bootstrap-icons'
import { OAuthProvider } from "firebase/auth";
import LoginUtils from './LoginUtils'

const LoginSocial = () => {
    const [socialResult, setSocialResult] = useState("")
    const [accessToken, setAccessToken] = useState("")
    const classes = useStyles()
    const translations = useTranslation()
    const [cookies] = useCookies([COOKIE_LOCATION, COOKIE_LANGUAGE])
    const location = cookies[COOKIE_LOCATION]
    const language = cookies[COOKIE_LANGUAGE] ?? 'en'
    const { pushToDataLayer } = useContext(GoogleEnhancedEcommerce)
    const { pushUser, pushContactAttribute } = useContext(SFMCCollectJs)
    const { cartId, setCartId } = useContext(LoginContext)
    const { setLoyaltyPoints } = useContext(LoyaltyPointsContext)
    const pageCfg = usePageCfg()
    const [signup] = useSignup()
    const { publicRuntimeConfig } = getConfig()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [errorProvider, setErrorProvider] = useState('')

    const { query } = useRouter()
    const isNeededForCheckout = query?.returnUrl === RETURN_URL_UNLOGGED_CHECKOUT
    const app = initializeApp(FIREBASE_CONFIG);
    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();
    const appleProvider = new OAuthProvider('apple.com');
    const auth = getAuth(app);
    const { currency } = storeKeys[location] || {}
    const storeKey = storeKeys[location]?.storeKey

    const confirmMail = async (user, email) => {
        await pageCfg.httpClient
            .post(`${pageCfg.cmsRestserviceUrl}/mailing/signup`, {
                customer: user,
                locale: regionLocales[location],
            })
            .then(() => {
                const dateOfBirth = user.dateOfBirth ? new Date(user.dateOfBirth) : undefined
                const day = dateOfBirth?.getDate() > 9 ? dateOfBirth?.getDate().toString() : '0' + dateOfBirth?.getDate().toString()
                const month = dateOfBirth?.getMonth() + 1 > 9 ? (dateOfBirth?.getMonth()+1).toString() : '0' + (dateOfBirth?.getMonth()+1).toString()
                const year = dateOfBirth?.getFullYear().toString()
                pageCfg.httpClient.post(`${pageCfg.cmsRestserviceUrl}/customer`, {
                    email: email,
                    country: location,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    type: 'SOC',
                    gender: user.gender ? (user.gender === 'male' ? 'U' : 'D') : '',
                    birthday: dateOfBirth ? '' + day + month + year : ''
                })
            })
            .catch((e) => {
                console.error('error', e)
            })
    }

    const updateCustomFields = async (email) => {
        await pageCfg.httpClient
            .post(`${pageCfg.cmsRestserviceUrl}/customer/update`, {
                email: email,
                store: 'SOC',
                inviter: ''
            })
            .catch((e) => {
                console.error('error', e)
            })
    }

    const googleSignIn = async () => {
        await signInWithPopup(auth, googleProvider)
            .then((result) => {
                setSocialResult(result);
                document.cookie = "cookieRefreshToken=" + JSON.stringify(result._tokenResponse.refreshToken);
                document.cookie = "cookieAccessToken=" + result._tokenResponse.idToken;
                setAccessToken(result._tokenResponse.idToken)
            }, ((error) => {
                if (error.code === "auth/account-exists-with-different-credential") {
                    setErrorProvider(error.customData._tokenResponse.verifiedProvider[0])
                    setIsDialogOpen(true)
                }
            }));

    }

    const facebookSignIn = async () => {
        await signInWithPopup(auth, facebookProvider)
            .then((result) => {
                setSocialResult(result);
                document.cookie = "cookieRefreshToken=" + "";
                document.cookie = "cookieAccessToken=" + result.oauthAccessToken;
                setAccessToken(result.oauthAccessToken)
            }, (error) => {
                if (error.code === "auth/account-exists-with-different-credential") {
                    setErrorProvider(error.customData._tokenResponse.verifiedProvider[0])
                    setIsDialogOpen(true)
                }
            });
    }

    const appleSignIn = async () => {
        appleProvider.addScope('name');
        appleProvider.addScope('email');
        appleProvider.setCustomParameters({
            locale: location || ''
          });
        await signInWithPopup(auth, appleProvider)
            .then((result) => {
                setSocialResult(result);
                const credential = OAuthProvider.credentialFromResult(result);
                document.cookie = "cookieRefreshToken=" + result.user.stsTokenManager.refreshToken;
                document.cookie = "cookieAccessToken=" + credential.accessToken;
                setAccessToken(credential.accessToken)
            }, (error) => {
                if (error.code === "auth/account-exists-with-different-credential") {
                    setErrorProvider(error.customData._tokenResponse.verifiedProvider[0])
                    setIsDialogOpen(true)
                }
            });
    }

    const encryptSocialToken = (accessToken, refreshToken) => {
        const keyStore = {
            kty: "oct",
            kid: "-q5_m-_Si1c3m4rOccOV7psfl4_9De0CsY7INLS5a8s",
            k: "9m3t1tmD5pxTmPCMeXOan-9v8TwhQ7TPjYUSPehVBko",
            enc: "A128CBC-HS256",
            alg: "A256KW",
        }


        axios.post(`${publicRuntimeConfig.cmsBaseUrl}/restservices/oauth/encrypt`, {
            accessToken: accessToken,
            refreshToken: refreshToken,
            kty: keyStore.kty,
            kid: keyStore.kid,
            k: keyStore.k,
            enc: keyStore.enc,
            alg: keyStore.alg,
            currency: currency,
            country: location,
            locale: regionLocales[location],
            cartId: cartId,
        }).then((response) => {
            setTokenInSession(response?.data?.token)
            if (response?.data?.userId){
                loggedSocial({id: response.data.userId}, {id: cartId})
            }
        }, (error) => {
            console.log(error);
        });

    }

    useEffect(() => {
        if (socialResult != "") {
            (async () => {
                console.log(socialResult)
                LoginUtils.checkCart(cartId, accessToken, storeKey, setCartId)
                const dateOfBirth = socialResult?._tokenResponse?.dateOfBirth ? new Date(socialResult?._tokenResponse?.dateOfBirth) : undefined
                const day = dateOfBirth?.getDate() > 9 ? dateOfBirth?.getDate().toString() : '0' + dateOfBirth?.getDate().toString()
                const month = dateOfBirth?.getMonth() + 1 > 9 ? (dateOfBirth?.getMonth()+1).toString() : '0' + (dateOfBirth?.getMonth()+1).toString()
                const year = dateOfBirth?.getFullYear().toString()
                await signup({
                    email: socialResult?.user?.email,
                    firstName: socialResult?._tokenResponse?.firstName || '',
                    lastName: socialResult?._tokenResponse?.lastName || '',
                    middleName: '',
                    dateOfBirth: dateOfBirth ? (year + '-' + month + '-' + day) : '',
                    salutation: socialResult?._tokenResponse?.gender || '',
                    password: Math.random().toString(36).slice(-10).toString()
                }).then((res) => {
                    const accessToken = socialResult._tokenResponse.idToken;
                    const refreshToken = socialResult._tokenResponse.refreshToken;

                    //check new user or return user
                    if (res?.message == "Successfully signed-up") {
                        encryptSocialToken(accessToken, refreshToken)
                        confirmMail(socialResult?._tokenResponse, socialResult?.user?.email)
                        updateCustomFields(socialResult?.user?.email)
                        pushToDataLayer({
                            event: 'registration',
                            eventCategory: 'Registrazione',
                            eventAction: 'registrationSuccess',
                            eventLabel: ''
                        })
                    } else {
                        encryptSocialToken(accessToken, refreshToken)
                    }
                })
            })();
        }
    }, [socialResult])


    const loggedSocial = async (customer, cart) => {
        console.log(customer);
        if (customer) {
            setCartId?.(cart?.id)

            pushToDataLayer({
                event: 'login',
                eventCategory: 'Login',
                eventAction: 'loginSuccess',
                eventLabel: customer.id
            })

            pushUser(customer.email)

            const loyaltyPoints = await synchronizeLoyaltyPoints(customer, pageCfg)
            setLoyaltyPoints(loyaltyPoints)

            pushContactAttribute({
                email: customer.email,
                gender: customer.salutation,
                name: customer.firstName + ' ' + customer.lastName,
                loyaltyStatus: loyaltyPoints.customerGroup,
                language: language
            })

            if(!isNeededForCheckout){
                sessionStorage.setItem('show_welcome_popup', true)
            }
            window.location.href = query.returnUrl ?? '/'
        }
    }

    return (
        <Grid container className={classes.textAlignCenter}>
            <Grid item xs={12} md={12} lg={4}>
                <BaseButton variant='outlined' className={classes.googleButton} size='small' onClick={googleSignIn} >
                    <Box m={1}>
                        <Google></Google>
                    </Box>
                    Google
                </BaseButton>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
                <BaseButton variant='outlined' className={classes.facebookButton} size='small' onClick={facebookSignIn} >
                    <Box m={1}>
                        <Facebook className="mr-2"></Facebook>
                    </Box>
                    Facebook
                </BaseButton>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
                <BaseButton variant='outlined' className={classes.appleButton} size='small' onClick={appleSignIn} >
                    <Box m={1}>
                        <Apple className="mr-2"></Apple>
                    </Box>
                    Apple
                </BaseButton>
            </Grid>

            <UserFeedbackDialog
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                handleAfterClose={() => { }}
            >
                <Typography variant='body1'>
                    {translations('login:user.already.registered')} {errorProvider}.
                    <br />
                    {translations('login:come.back.to.login.page')}
                </Typography>
            </UserFeedbackDialog>
        </Grid>
    )
}


export default LoginSocial
