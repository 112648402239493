import makeStyles from '@material-ui/styles/makeStyles'
import theme from '../../../../lib/theme'

export default makeStyles(() => ({
    addButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(7)
    },
    formSelect: {
        width: '100%',
        '& .MuiSelect-selectMenu': {
            height: '1.76em'
        } 
    },
    form: {
        padding: theme.spacing(2),
        maxWidth: theme.breakpoints.values.md,
        margin: '0 auto',
    }
}))
