import { Grid, Link, Step, StepLabel, Stepper, Typography, Box } from "@material-ui/core"
import { useMemo, useState, useContext, useEffect  } from "react"
import { isMobileOrTablet } from "../../../lib/utils"
import BaldininiLogo from '../../../public/Baldinini-Logo.svg'
import useStyles from './style'
import useTranslation from "../../_Elements/ResourceBundles/useTranslation"
import NavigationButtons from "../Checkout/Checkout/components/NavigationButtons/NavigationButtons"
import SideSummaryCart from "../Checkout/Checkout/components/SideSummaryCart/SideSummaryCart"
import Maps from "./Maps/Maps"
import Address from "../Checkout/Checkout/components/StepContent/AddressSelection/Address/Address"
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined'
import { useCart, useOrder, useRemoveCartDiscount } from '@bloomreach/connector-components-react'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'
import LoginContext from '../Account/Login/LoginContext'
import useTrackPurchase from '../../_Hooks/HeroTracker/useTrackPurchase'
import { COOKIE_LOCATION } from '../../_Mappings/cookies'
import storeKeys from '../../_Mappings/storeKeys'
import BaseButton from '../../_Elements/Inputs/Button/BaseButton'
import useGoogleTrackPurchase from '../../_Hooks/GoogleAnalytics/useGoogleTrackPurchase'
import CheckOutContext from '../Checkout/CheckOutContext'
import useRemoveCartItems from '../../_Hooks/useClearCart'
import { SFMCCollectJs } from '../ThirdPartyScripts/SFMCCollectJs'


const ThankyouPageComponent = () => {

    const translations = useTranslation()
    const classes = useStyles()

    const steps = useMemo(() => [
        { id: 'addresses', label: translations('checkout:addresses') },
        { id: 'payment', label: translations('checkout:payment') },
        { id: 'review', label: translations('checkout:review') },
        { id: 'done', label: translations('checkout:done') }
    ].filter(Boolean), [translations])

    const updateOrderSteps = useMemo(() => [
        { id: 'confirmed', label: translations('checkout:thankyou.confirmed'), icon: EmailOutlinedIcon },
        { id: 'ready', label: translations('checkout:thankyou.ready'), icon: PaymentOutlinedIcon },
        { id: 'shipped', label: translations('checkout:thankyou.shipped'), icon: CheckOutlinedIcon },
    ].filter(Boolean), [translations])
    
    const removeCartItems = useRemoveCartItems()

    // Store
    const [cookies] = useCookies([COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const { storeKey } = storeKeys[location] || {}

    // Router
    const { query, push } = useRouter()

    // Cart
    const { cartId } = useContext(LoginContext)
    const [cart] = useCart({ cartId, storeKey })
    const [removeCartDiscount] = useRemoveCartDiscount({ cartId })
    const { trackPurchase } = useContext(SFMCCollectJs)

    // Order
    const { orderId } = useContext(CheckOutContext)
    const [order, orderLoading] = useOrder({ orderId: query?.orderId ?? orderId })

    const paymentName = query?.paymentName ?? ""

    // Reset CartID
    useEffect(() => {
        if (cart && order && cart.id !== order.id) {
            (async () => {
                await removeCartItems()
                await removeCartDiscount({
                    discountCodes:
                        cart.discounts
                            ?.filter((d) => d.type === 'Promotion')
                            .map((discount) => discount.id)
                })
            })()
        }
    }, [cart, order])

    useTrackPurchase(cart, order)
    useGoogleTrackPurchase(cart, order)
    useEffect(() => {
        if(cart && cart.entries){
            trackPurchase(cart.entries)
        }
    }, [cart])

    return (
        <>
            <Link
                color='inherit'
                href='/'
                className={classes.logoContainer}
            >
                <BaldininiLogo />
            </Link>

            
            <Stepper id="MuiStepper-horizontal" activeStep={3} className={classes.customStepper} nonLinear={isMobileOrTablet()} alternativeLabel={!isMobileOrTablet()}>
                {steps.map(({ id, label }) => (
                    <Step key={id} id={id}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Grid container >
                <Grid item lg={9}>

                    <Box className={classes.congratulations}>
                        <Typography variant="body1">
                            {translations('checkout:thankyou.order')} #{order?.orderNumber}
                        </Typography>
                        <Typography variant="h3">
                            {translations('checkout:thankyou.congratulations')} {order?.billingAddress?.firstName}
                        </Typography>
                        <Typography variant="h3">
                            {translations('checkout:thankyou.title')}
                        </Typography>
                        <Typography variant="body1">
                            {translations('checkout:thankyou.subtitle')}
                        </Typography>
                    </Box>

                    <Box className={classes.thankyouPageContainer}>

                        <Box className={classes.stepperOrderContainer}>
                            <Typography variant="h5" className={classes.fontWeightBold}>
                                {translations('checkout:thankyou.step.title')}
                            </Typography>
                            <Typography variant="body1">
                                {translations('checkout:thankyou.step.subtitle')}
                            </Typography>
                            <Stepper activeStep={0} alternativeLabel className={classes.customStepperOrder}>
                                {updateOrderSteps.map(( step, index ) => (
                                    <Step key={step.id} id={step.id}>
                                        <StepLabel className={index === 0 ? "active-order" : ""} StepIconComponent={step.icon}>{step.label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>

                        <Box className={classes.thankyouPageBox}>
                            <Typography variant="body1" className={classes.fontWeightBold}>
                                {translations('checkout:thankyou.moreInformation')}
                            </Typography>
                            <ul className={classes.decimalList}>
                                <li>
                                    {translations('checkout:thankyou.moreInformation.tracking')}
                                </li>
                                <li>
                                    {translations('checkout:thankyou.moreInformation.processing')}
                                </li>
                                <li>
                                    {translations('checkout:thankyou.moreInformation.packing')}
                                </li>
                                <li>
                                    {translations('checkout:thankyou.moreInformation.shipping')}
                                </li>
                            </ul>
                        </Box>

                        <Box className={classes.thankyouPageBox}>
                            <Typography variant="h5" className={classes.fontWeightBold}>
                                {translations('checkout:thankyou.maps.title')}
                            </Typography>
                            <Typography variant="body1" className={classes.fontWeightBold}>
                                {translations('checkout:thankyou.maps.subtitle')}
                            </Typography>
                            {order && (
                                <Maps order={order} />
                            )}
                        </Box>

                        <Box className={classes.thankyouPageBox}>
                            <Typography variant="h5" className={classes.fontWeightBold}>
                                {translations('checkout:thankyou.data')}
                            </Typography>

                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography
                                        variant='body1'
                                        className={classes.fontWeightBold}
                                    >
                                        {translations('address:shipping.address.title')}
                                    </Typography>
                                    {order && <Address active address={order?.shippingAddress} className={classes.addressesReview} />}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography
                                        variant='body1'
                                        className={classes.fontWeightBold}
                                    >
                                        {translations('address:shipping.address.title')}
                                    </Typography>
                                    {order && <Address active address={order?.billingAddress} className={classes.addressesReview} />}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="body1" className={classes.fontWeightBold}>
                                            
                                    </Typography>
                                    <Typography variant="body1">
                                        {paymentName}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Box>

                    </Box>

                    
                </Grid>
                <Grid item lg={3}>
                    {order && (
                        <SideSummaryCart order={order} />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default ThankyouPageComponent