import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    openingHourContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5)
    },
    gridItem: {
        textAlign: 'end'
    }
}))
