import { Box } from '@material-ui/core'
import {
    shape, number, oneOfType, string, node
} from 'prop-types'
import Typography from '@material-ui/core/Typography'
import useStyles from './style'

const LabelSelection = ({ option }) => {
    const classes = useStyles()
    const { img, label, logo = null } = option
    const { width = '', height = '', src } = img ?? {}

    return (
        <Box
            display='flex'
            alignItems='center'
        >
            <Typography>{label}</Typography>
            <Box display='flex'>
                {img?.src?.map((url) => {
                    return (<img
                        alt={`${label} logo`}
                        className={classes.image}
                        src={url}
                        style={{ width, height }}
                    />)
                })} 
            </Box>

            {logo
                && (
                    <div className={classes.image}>{logo}</div>
                )}
        </Box>
    )
}

LabelSelection.propTypes = {
    option: oneOfType([
        shape({
            label: string,
            value: oneOfType([number, string]),
            img: shape({
                src: string,
                width: number,
                height: number
            })
        }),
        shape({
            label: string,
            value: oneOfType([number, string]),
            logo: node
        })
    ])
}

LabelSelection.defaultProps = {
    option: null
}

export default LabelSelection
