import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api'
import {
    useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react'
import {
    CircularProgress,
    Container,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    LinearProgress,
    NoSsr,
    Paper,
    Typography
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import clsx from 'clsx'
import { BrPageContext } from '@bloomreach/react-sdk'
import useStyles from './style'
import Location from '../../Storelocator/Location/Location'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'

const containerStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
}

const libraries = ['places']

const MapsComponent = ({shippingAddress}) => {
    const t = useTranslation()
    const classes = useStyles()
    const page = useContext(BrPageContext)

    const {
        googleMapsApiKey
    } = page?.getChannelParameters() ?? {}

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey,
        libraries
    })

    const [map, setMap] = useState(null)
    
    const [addressCoordinates, setAddressCoordinates] = useState()
    const [showInfoWindow, setShowInfoWindow] = useState(true)

    const standardAddress = shippingAddress.streetAddress + " " + shippingAddress.city + " " + shippingAddress.state + " " + shippingAddress.postalCode + " " + shippingAddress.country

    const onLoad = useCallback(async (_map) => {

        console.log("_map", _map)

        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({address: standardAddress}).then((result) => {
            const { results } = result

            _map.setZoom(12)
            _map.setCenter(results[0].geometry.location)
            setMap(_map)
            setAddressCoordinates(results[0].geometry.location)

        }).catch((e) => {
            console.error(e)
        })

    }, [])

    const onUnmount = useCallback(() => {
        setMap(null)
    }, [])

    if (!isLoaded) return null

    return (
        <Container
            className={classes.storelocator}
            maxWidth='xl'
        >

            <Grid
                className={classes.container}
                container
                direction='row'
            >
                { !map && (
                    <div
                        className={classes.loadingOverlay}
                    >
                        <CircularProgress />
                    </div>
                )}
                
                <Grid
                    xs={12}
                    item
                    container
                >
                    <Grid
                        item
                        xs
                    >
                        <Paper
                            variant='outlined'
                            className={classes.mapContainer}
                        >
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                <Marker
                                    position={{ lat: addressCoordinates?.lat(), lng: addressCoordinates?.lng() }}
                                    onClick={() => setShowInfoWindow(true)}
                                    clickable
                                >
                                    {addressCoordinates && showInfoWindow && 
                                        (<InfoWindow
                                            position={{ lat: addressCoordinates?.lat(), lng: addressCoordinates?.lng() }}
                                            onCloseClick={() => setShowInfoWindow(false)}
                                            >
                                                <div className={classes.infoWindowText}>
                                                    <Typography variant="body1">
                                                        {t('checkout:thankyou.maps.subtitle')}:
                                                    </Typography>
                                                    <Typography variant="body1" className={classes.fontWeightBold}>
                                                        {standardAddress}
                                                    </Typography>
                                                </div>
                                        </InfoWindow>)
                                    }
                                </Marker>

                                    
                            </GoogleMap>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

        </Container>
    )
}

const Maps = ({order}) => (
    <NoSsr>
        {console.log(order)}
        <MapsComponent shippingAddress={order.shippingAddress}/>
    </NoSsr>
)

export default Maps
