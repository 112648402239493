import { useContext, useState } from 'react'
import { BrComponentContext, BrPageContext } from '@bloomreach/react-sdk'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import useStyles from './style'
import BaseButton from '../../_Elements/Inputs/Button/BaseButton'
import { useRouter } from 'next/router'
import AddIcon from '@material-ui/icons/Add';
import useTranslation from '../../_Elements/ResourceBundles/useTranslation'
import { ExpandMore } from '@material-ui/icons'

const Faq = () => {
    const classes = useStyles()
    const page = useContext(BrPageContext)
    const component = useContext(BrComponentContext)
    const router = useRouter()
    const t = useTranslation()

    const [isThankYouPage, setIsThankYouPage] = useState(false)

    const entries = component.getModels().faqEntries

    if (!entries) return null

    const entriesWithContent = {}
    Object.entries(entries).forEach(([key, value]) => {
        entriesWithContent[key] = value.map((faqRef) => page?.getContent(faqRef)).map((model) => model.model.data).sort((a, b) => a.sortOrderField - b.sortOrderField)
    })

    React.useEffect(() => {
        if(window?.location.href.includes("thankyoupage")){
            setIsThankYouPage(true)
        }else{
            setIsThankYouPage(false)
        }
      }, []);

    return (
        <>
            {isThankYouPage ? (
                <Grid container >
                    <Grid item lg={9}>
                        <Box className={classes.thankyouPageContainer}>
                            <FaqElement entriesWithContent={entriesWithContent} classes={classes} isThankYouPage={isThankYouPage}/>
                            <BaseButton
                                variant='contained'
                                color='primary'
                                type='button'
                                onClick={() => {router.push({pathname: '/footer/faq'})}}
                            >
                                {t('checkout:thankyou.faq.button')}
                            </BaseButton>
                        </Box>
                    </Grid>
                    <Grid item lg={3} className={classes.fakeSideSummaryCart}>
                    </Grid>
                </Grid>
            ) : (
                <Container maxWidth='md'>
                    <FaqElement entriesWithContent={entriesWithContent} classes={classes} />
                </Container>
            )}
        </>
    )
}

const FaqElement = ({entriesWithContent, isThankYouPage, classes}) => {
    return(
        <>
            { Object.entries(entriesWithContent).map(([folder, faqList]) => faqList.length > 0 && (
                <div
                    key={folder}
                    className={classes.faq}
                >
                    <Typography variant='h4'>{ folder }</Typography>
                    {faqList.map((el) => (
                        <Accordion
                            key={el.id}
                        >
                            <AccordionSummary
                                expandIcon={isThankYouPage ? <AddIcon /> : <ExpandMore />}
                                aria-controls='panel1a-content'
                                id='panel1a-content'
                            >
                                <Typography>
                                    {el.questionField}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div dangerouslySetInnerHTML={{ __html: el.answerField.value }} className={classes.mobileOverflow} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            ))}
        </>
    )
}

export default Faq
