import React from 'react'
import { bool, object } from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { Divider, Grid } from '@material-ui/core'
import useStyles from './style'
import Link from '../../../../_Elements/Link'
import useTranslation from '../../../../_Elements/ResourceBundles/useTranslation'

const Details = ({ isLoading, placeDetails }) => {
    const classes = useStyles()
    const t = useTranslation()

    if (!(placeDetails || isLoading)) return null

    if (isLoading) {
        return (
            <div>
                <Divider className={classes.divider} light variant='middle' />
                <CircularProgress />
            </div>
        )
    }

    const {
        name,
        website,
        business_status: businessStatus,
        opening_hours: openingHours,
        international_phone_number: internationalPhoneNumber
    } = placeDetails

    let websiteDisplayName

    try {
        websiteDisplayName = new URL(website).hostname.replace('www.', '')
    } catch (e) {
        websiteDisplayName = name
    }

    const getBusinessStatusOutput = () => {
        const translationPrefix = 'storelocator.detail.openinghours'

        switch (businessStatus) {
        case 'CLOSED_PERMANENTLY':
            return t(`${translationPrefix}.general.closed.permanently`)
        case 'CLOSED_TEMPORARILY':
            return t(`${translationPrefix}.general.closed.temporarily`)
        case 'OPERATIONAL':
            return t(`${translationPrefix}.currently.${openingHours?.isOpen() ? 'open' : 'closed'}`)
        default:
            return ''
        }
    }

    // Thanks to:
    // https://stackoverflow.com/questions/48461740/using-functional-programming-to-parse-opening-hours-from-google-places-api

    const days = [0, 1, 2, 3, 4, 5, 6]
    const mappedDays = {
        0: 6,
        1: 0,
        2: 1,
        3: 2,
        4: 3,
        5: 4,
        6: 5
    }

    const dayTitle = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

    let periodsOpeningHours

    if (openingHours?.periods?.length === 1) {
        const openAllDay = t('storelocator.detail.openinghours.openallday')
        periodsOpeningHours = [0, 1, 2, 3, 4, 5, 6].map((p, index) => ({ [index]: [[openAllDay]] }))
    } else {
        periodsOpeningHours = openingHours?.periods.map((p) => ({
            day: mappedDays[p.open.day],
            time: `${p.open?.time?.match(/.{1,2}/g).join(':')}-${p.close?.time?.match(/.{1,2}/g).join(':')}`
        }))
            .reduce((acc, current) => {
                const time = acc[current.day]
                time.push(current.time)
                return Object.assign([], acc, { [current.day]: time })
            }, days.map(() => []))
            .map((p, index) => ({ [index]: p }))
    }

    return (
        <div>
            <Divider className={classes.divider} light variant='middle' />

            <Typography variant='subtitle1'>{getBusinessStatusOutput()}</Typography>

            {businessStatus === 'OPERATIONAL' && periodsOpeningHours && (
                <div className={classes.openingHourContainer}>
                    <Typography variant='button' display='block'>{`${t('storelocator.detail.openinghours.headline')}:`}</Typography>
                    <Grid container>
                        {periodsOpeningHours.map((preOpeningHourDay, index) => preOpeningHourDay[index]).map((openingHourDay, index) => {
                            const dayOfWeek = t(`daysofweek.${dayTitle[index]}`)

                            let openingObj = <Typography variant='caption' display='block'>{t('storelocator.detail.openinghours.weekday.closed')}</Typography>

                            if (openingHourDay.length) {
                                openingObj = openingHourDay
                                    // eslint-disable-next-line react/no-array-index-key
                                    .map((entry, _index) => <Typography key={`${dayOfWeek}${_index}`} variant='caption' display='block'>{entry}</Typography>)
                            }

                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <Grid container item key={index}>
                                    <Grid item xs>
                                        <Typography variant='caption' display='block'>{`${dayOfWeek}:`}</Typography>
                                    </Grid>
                                    <Grid item className={classes.gridItem} xs='auto' md={12} lg='auto' zeroMinWidth>{ openingObj }</Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            )}
            <Grid container>

                {internationalPhoneNumber && (
                    <Grid container item>
                        <Grid item xs>
                            <Typography variant='button' display='block'>{`${t('storelocator.detail.phone')}:`}</Typography>
                        </Grid>
                        <Grid item className={classes.gridItem} xs='auto' md={12} lg='auto' zeroMinWidth>
                            <Typography variant='button' display='block'>{internationalPhoneNumber}</Typography>
                        </Grid>
                    </Grid>
                )}

                {website && (
                    <Grid container item>
                        <Grid item xs>
                            <Typography variant='button' display='block'>{`${t('storelocator.detail.website')}: `}</Typography>
                        </Grid>
                        <Grid item className={classes.gridItem} xs='auto' md={12} lg='auto' zeroMinWidth>
                            <Typography variant='button' display='block'>
                                <Link
                                    color='inherit'
                                    href={website}
                                    title={website}
                                >
                                    {websiteDisplayName}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

Details.propTypes = {
    placeDetails: object,
    isLoading: bool
}

Details.defaultProps = {
    placeDetails: null,
    isLoading: true
}

export default Details
