import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    flex: {
        display: 'flex'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    registerContainer: {
        padding: theme.spacing(5, 0),
        textAlign: 'center',
        width: '70vw'
    },
    loginTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    loginSubtitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    googleButton: {
        // background: '#dd4b39',
        minWidth: '250px',
        '&:hover': {
            padding: '6px 10px'
        }
    },
    facebookButton: {
        // backgroundColor: '#3B5998',
        minWidth: '250px',
        '&:hover': {
            padding: '6px 10px'
        }
    },
    appleButton: {
        // backgroundColor: '#000000',
        minWidth: '250px',
        '&:hover': {
            padding: '6px 10px'
        }
    },
    logoContainer: {
        width: '100%',
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    },
    forgotPasswordLink: {
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: theme.spacing(2),
    },
    form: {
        width: '100%'
    },
    serviceMessage: {
        margin: theme.spacing(1, 0)
    },
    loginWrap:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(0, 'auto'),
        flexWrap: 'wrap',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            margin: theme.spacing(0)
        }
    },
    halfWrap: {
        width: '50%',
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    loginPopContainer: {
        padding: '10px'
    },
    flexElement: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        justifyContent: 'center'
    },
    orderAsGuestContainer: {
        background: '#f1f1f1',
        padding: '50px 0',
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: {
            padding: '50px 16px',
        }
    },
    textDividerPadding: {
        padding: theme.spacing(6)
    },
    loginFormContainer: {
        [theme.breakpoints.up('sm')]: {
            minHeight: '100vh',
        }
    },
    textAlignCenter: {
        textAlign: 'center'
    }
}))
