import {
    useContext, useEffect, useState
} from 'react'
import List from '@material-ui/core/List'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import { Spinner } from 'react-bootstrap'
import { object } from 'prop-types'
import { Container } from '@material-ui/core'
import LoginContext from '../../Account/Login/LoginContext'
import Link from '../../../_Elements/Link'
import CartItem from '../CartItem/CartItem'
import useStyles from './style'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import PriceSummary from '../PriceSummary/PriceSummary'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import StockVerificationDialog from '../../StockVerification/StockVerificationDialog'
import warehouseMap from '../../../_Mappings/warehouseMap'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import Discount from '../Discount/Discount'
import useRemoveCartItems from '../../../_Hooks/useClearCart'
import SubtotalCard from '../PriceSummary/SubtotalCard'
import storeKeys from '../../../_Mappings/storeKeys'
import useCustomCart from '../../../_Hooks/useCustomCart/useCustomCart'
import useGoogleBasket from '../../../_Hooks/GoogleAnalytics/useGoogleBasket'

const CartComponent = ({ page }) => {
    const classes = useStyles()
    const translations = useTranslation()
    const pageCfg = usePageCfg()
    const router = useRouter()
    const { cartId, setCartId } = useContext(LoginContext)
    const [summary, setSummary] = useState({ totalPurchasePrice: {}, totalQuantity: 0, totalListPrice: {}, discounts: [] })

    const removeCartItems = useRemoveCartItems()

    // Read user location from cookies for warehouse assignment
    // warehouseMap.js
    const [cookies] = useCookies([COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const { storeKey } = storeKeys[location] || {}
    const { cart, refetch, loading } = useCustomCart()

    const [itemsToVerify, setItemsToVerify] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [verificationDialog, setVerificationDialog] = useState({ open: false, items: [] })

    useEffect(() => {
        if (!cart) return

        const data = cart.entries.map((cartItem) => cartItem.items.map((item) => {
            const [sku, size] = item.itemId.code.split('-')

            return {
                sku,
                size,
                quantity: cartItem.quantity
            }
        })).flat()

        setItemsToVerify(data)
    }, [cart])

    useEffect(() => {
        if (!cart) return
        const { totalPurchasePrice: tpp, totalListPrice: tlp, totalQuantity: tq, discounts} = cart
        setSummary({
            totalPurchasePrice: tpp?.moneyAmounts[0],
            totalQuantity: tq,
            totalListPrice: tlp.moneyAmounts[0],
            discounts
        })
    }, [cart])

    useEffect(() => {
        if (cart && setCartId) {
            setCartId(cart.id)
        }
    }, [cart, setCartId])

    useGoogleBasket(cart)

    const handleClose = () => {
        setVerificationDialog(false)
    }

    const handleOnClick = async () => {
        await refetch()

        sessionStorage.removeItem('shippingAddress')
        sessionStorage.removeItem('billingAddress')
        const productStockDataList = itemsToVerify.map((entry) => {
            const { sku, size } = entry
            return { sku, size }
        })

        try {
            setIsLoading(true)
            const response = await pageCfg.httpClient.post(`${pageCfg.cmsRestserviceUrl}/stock`, {
                warehouse: warehouseMap[location],
                productStockDataList
            })

            const unavailable = await itemsToVerify?.map((item) => item.quantity)
                .map((item, index) => (item > response?.data[index].quantity ? itemsToVerify[index] : undefined))
                .filter(Boolean)
                .map((product) => ({ skuSize: product.size ? `${product.sku}-${product.size}` : product.sku }))

            // Array der zu löschenden ProduktIds
            const productsToDelete = unavailable
                .map((product) => cart.entries.find((cartItem) => cartItem?.items[0].itemId.code === product.skuSize)?.id)

            // Array der zu löschenden Produktitems
            const invalidProducts = unavailable
                .map((product) => cart.entries.find((cartItem) => cartItem?.items[0].itemId.code === product.skuSize)?.items[0]?.itemId.id)
                .map((id) => cart.entries.filter((x) => x.items[0].itemId.id.includes(id)))
                .flat()

            if (productsToDelete.length === 0) {
                router.push({
                    pathname: page.getUrl('/checkout')
                })
            } else {
                setVerificationDialog({ open: true, items: invalidProducts })
                await removeCartItems(productsToDelete)
            }

            setIsLoading(false)
        } catch (e) {
            console.error('error', e)
        }
    }

    if (loading) return <Spinner animation='border' />

    const emptyCart = () => (
        <div className={classes.emptyCartContainer}>
            <Typography variant='h6' className={classes.emptyCartText}>
                {translations('checkout:cart.empty.text')}
            </Typography>
            <Link
                href={page.getUrl('/')}
                role='button'
                className={classes.button}
            >
                <BaseButton data-qa='button-cart-empty'>
                    {translations('checkout:cart.empty.button')}
                </BaseButton>
            </Link>
        </div>
    )

    return (
        <Container maxWidth='xl'>
            <StockVerificationDialog
                open={verificationDialog.open}
                onClose={handleClose}
                origin='Cart'
                productsToDelete={verificationDialog.items}
            />
            {cart?.entries.length > 0
                ? (
                    <div className={classes.root}>
                        <Typography component='h2' variant='h5' align='center' className={classes.title}>
                            {translations('checkout:cart.title')}
                        </Typography>
                        <Box className={classes.flexEnd}>
                            <BaseButton
                                variant='contained'
                                disableElevation
                                role='button'
                                className={classes.button}
                                loading={isLoading}
                                onClick={handleOnClick}
                                data-qa='button-cart-proceedToCheckout'
                            >
                                {translations('checkout:cart.to.checkout.button')}
                            </BaseButton>
                        </Box>
                        <List aria-label='cart product list' className={classes.itemList}>
                            {
                                cart.entries?.filter(Boolean).map((entry, index) => (
                                    <CartItem
                                        key={entry.id}
                                        entry={entry}
                                        cartId={cart.id}
                                        isCart
                                        position={index}
                                    />
                                ))
                            }
                        </List>
                        <div className={classes.summaryContainer}>
                            <SubtotalCard totalListPrice={summary.totalListPrice} />
                            <Discount cartId={cartId} storeKey={storeKey} />
                            <PriceSummary
                                totalPurchasePrice={summary.totalPurchasePrice}
                                totalListPrice={summary.totalListPrice}
                                totalQuantity={summary.totalQuantity}
                                discounts={summary.discounts}
                            />
                        </div>
                        <Box className={classes.flexEnd}>
                            <BaseButton
                                variant='contained'
                                disableElevation
                                role='button'
                                className={classes.button}
                                loading={isLoading}
                                onClick={handleOnClick}
                                data-qa='button-cart-proceedToCheckout'
                            >
                                {translations('checkout:cart.to.checkout.button')}
                            </BaseButton>
                        </Box>
                    </div>
                )
                : emptyCart()}
        </Container>
    )
}

CartComponent.propTypes = {
    page: object
}

CartComponent.defaultProps = {
    page: undefined
}

export default CartComponent
