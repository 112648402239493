import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    buttons: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4)
    },
    back: {
        fontWeight: 700,
        textDecoration: 'underline !important',
        fontSize: '16px',
        textTransform: 'capitalize',
        margin: theme.spacing(0, 3),
        padding: 0,
        '&:hover': {
            '-webkit-text-stroke': '0px black'
        }
    },
    next: {
        marginBottom: theme.spacing(7)
    }
}))
