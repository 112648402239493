import { func } from 'prop-types'
import React, {
    useCallback,
    useContext, useEffect, useRef, useState
} from 'react'
import Typography from '@material-ui/core/Typography'
import {
    Checkbox, CircularProgress, Collapse, FormControlLabel, Grid, Box
} from '@material-ui/core'
import { BrPageContext } from '@bloomreach/react-sdk'
import useStyles from '../style'
import BaseButton from '../../../../../../_Elements/Inputs/Button/BaseButton'
import useTranslation from '../../../../../../_Elements/ResourceBundles/useTranslation'
import AddAddress from '../../../../../Account/AddAddress/AddAddress'
import useAddresses from './useAddresses'
import CheckOutContext from '../../../../CheckOutContext'
import Address from './Address/Address'
import AddAddressButton from '../../../../AddAddressButton/AddAddressButton'
import NavigationButtons from '../../NavigationButtons/NavigationButtons'
import LoginContext from '../../../../../Account/Login/LoginContext'
import ChangeAddressButton from '../../../../ChangeAddressButton/ChangeAddressButton'
import Link from '../../../../../../_Elements/Link'
import useCustomSetOrderDetails from '../../../../../../_Hooks/useCustomCheckout/useCustomSetOrderDetails'
import SideSummaryCart from '../../SideSummaryCart/SideSummaryCart'

const isValidAddress = ({
    firstName,
    lastName,
    country,
    city,
    postalCode,
    streetAddress,
    phone
}) => firstName !== null
    && lastName !== null
    && country !== null
    && city !== null
    && postalCode !== null
    && streetAddress !== null
    && phone !== null

const AddressSelection = ({ onNext }) => {
    const page = useContext(BrPageContext)
    const { user } = useContext(LoginContext)

    const [showShippingAddresses, setShowShippingAddresses] = useState(false)
    const [showBillingAddresses, setShowBillingAddresses] = useState(false)

    const t = useTranslation()
    const classes = useStyles()

    const addresses = useAddresses()
    const { billingAddress, setBillingAddress, shippingAddress, setShippingAddress } = useContext(CheckOutContext)

    const requestIsRunningRef = useRef(false)
    const onAddAddress = useCallback(async ({ email, ...values }) => {
        if (setShippingAddress && setBillingAddress) {
            const { id } = values
            setBillingAddress({ addressId: id, address: values, email })
            setShippingAddress({ addressId: id, address: values, email })
        }
    }, [setBillingAddress, setShippingAddress])

    const onShippingAddressChanged = useCallback(async ({ email, ...values }) => {
        const { id } = values
        if (JSON.stringify(billingAddress) === JSON.stringify(shippingAddress)) {
            setBillingAddress({ addressId: id, address: values, email })
            setShippingAddress({ addressId: id, address: values, email })
        } else {
            setShippingAddress({ addressId: id, address: values, email })
        }
    }, [setShippingAddress, shippingAddress])

    const onBillingAddressChanged = useCallback(async ({ email, ...values }) => {
        const { id } = values
        setBillingAddress({ addressId: id, address: values, email })
    }, [setBillingAddress, billingAddress])

    useEffect(() => {
        (async () => {
            // SET ORDER ADDRESSES IF USER HAS VALID ADDRESSES
            if (!shippingAddress?.address && !requestIsRunningRef.current && addresses.list.length > 0) {
                requestIsRunningRef.current = true
                setShippingAddress({addressId: addresses.defaultShippingAddress?.id, address: addresses.defaultShippingAddress})
                setBillingAddress({addressId: addresses.defaultShippingAddress?.id, address: addresses.defaultShippingAddress})
                requestIsRunningRef.current = false
            }
        })()
    }, [shippingAddress, addresses.list.length])

    useEffect(() => {
        if (showBillingAddresses) setShowBillingAddresses(() => false)
    }, [billingAddress?.addressId])

    useEffect(() => {
        if (showShippingAddresses) setShowShippingAddresses(() => false)
    }, [shippingAddress?.addressId])

    if (!shippingAddress) return null

    return (
        <div
            className={classes.addressesContainer}
        >
            { addresses.isLoading && <div className={classes.loadingOverlay}><CircularProgress /></div> }
            { !(shippingAddress?.address && isValidAddress(shippingAddress?.address)
                && (user?.anonymous || addresses.list?.some(({ id }) => id === shippingAddress?.addressId)))
                ? (
                    <>
                        <Grid container >
                            <Grid item lg={9}>
                                <NavigationButtons
                                    hrefBack={page.getUrl('/cart')}
                                    component={Link}
                                    dataQaBack='button-checkout-address-back'
                                />

                                <Box className={classes.header}>
                                    <Typography
                                        variant='h6'
                                        align='center'
                                        className={classes.titleStyle}
                                        gutterBottom
                                    >
                                        {t('checkout:step.header.address')}
                                    </Typography>
                                    <Typography variant="body1" align='center'>
                                        {t('checkout:step.header.address.subtitle')}
                                    </Typography>
                                </Box>

                                <AddAddress onSubmit={onAddAddress} onSuccess={onShippingAddressChanged}/>
                            </Grid>
                            <Grid item lg={3}>
                                <SideSummaryCart />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid container >

                            <Grid item xs={12} lg={9}>

                                <NavigationButtons
                                    hrefBack={page.getUrl('/cart')}
                                    component={Link}
                                    dataQaBack='button-checkout-address-back'
                                />

                                <Box className={classes.header}>
                                    <Typography
                                        variant='h6'
                                        align='center'
                                        className={classes.titleStyle}
                                        gutterBottom
                                    >
                                        {t('checkout:step.header.address')}
                                    </Typography>
                                    <Typography variant="body1" align='center'>
                                        {t('checkout:step.header.address.subtitle')}
                                    </Typography>
                                </Box>

                                <Box className={classes.containerSelectedAddresses}>
                                    <Grid
                                        container
                                        className={classes.shippingBilligContainer}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            direction='column'
                                            container
                                        >
                                            <Typography
                                                variant='h6'
                                                gutterBottom
                                                className={classes.titleStyle}
                                            >
                                                {t('address:shipping.address.title')}
                                            </Typography>

                                            <Address active address={shippingAddress.address} />

                                            { !user.anonymous
                                                ? (
                                                    <>
                                                        <div>
                                                            <BaseButton
                                                                size='small'
                                                                color={showShippingAddresses ? 'secondary' : 'primary'}
                                                                onClick={() => {
                                                                    setShowShippingAddresses((ssa) => !ssa)
                                                                }}
                                                                className={classes.buttonCheckout}
                                                            >
                                                                { t('checkout:choose.shipping.address.title') }
                                                            </BaseButton>
                                                        </div>

                                                        <Collapse
                                                            in={showShippingAddresses}
                                                        >
                                                            { addresses.list.map((address) => (
                                                                <Address
                                                                    onClick={() => {
                                                                        addresses.setShippingAddress(address.id)
                                                                        setShippingAddress({address, addressId: address.id})
                                                                    }}
                                                                    isSelectable
                                                                    key={address.id}
                                                                    active={address.id === shippingAddress.addressId}
                                                                    address={address}
                                                                />
                                                            )) }
                                                            <AddAddressButton size='small' data-qa='button-accountAddresses-AddAddressButton' className={classes.buttonCheckout} />
                                                        </Collapse>
                                                    </>
                                                )
                                                : (
                                                    <div>
                                                        <ChangeAddressButton setAddress={onShippingAddressChanged} />
                                                    </div>
                                                )}
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            direction='column'
                                            container
                                        >
                                            <Typography
                                                variant='h6'
                                                className={classes.titleStyle}
                                                gutterBottom
                                            >
                                                { t('address:billing.address.title') }
                                            </Typography>
                                            { JSON.stringify(shippingAddress) === JSON.stringify(billingAddress)
                                                ? (
                                                    <div
                                                        className={classes.checkboxContainer}
                                                    >
                                                        <FormControlLabel
                                                            label={t('checkout:same.as.shipping.checkbox')}
                                                            control={(
                                                                <Checkbox
                                                                    color='primary'
                                                                    checked
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )
                                                : (
                                                    <Address active address={billingAddress.address} />
                                                )}
                                            { !user.anonymous
                                                ? (
                                                    <>
                                                        <div>
                                                            <BaseButton
                                                                size='small'
                                                                color={showBillingAddresses ? 'secondary' : 'primary'}
                                                                onClick={() => {
                                                                    setShowBillingAddresses((sba) => !sba)
                                                                }}
                                                                className={classes.buttonCheckout}
                                                            >
                                                                { t('checkout:choose.billing.address.title') }
                                                            </BaseButton>
                                                        </div>
                                                        <Collapse
                                                            in={showBillingAddresses}
                                                        >
                                                            { addresses.list.map((address) => (
                                                                <Address
                                                                    onClick={() => {
                                                                        addresses.setBillingAddress(address.id)
                                                                        setBillingAddress({address, addressId: address.id})
                                                                    }
                                                                    }
                                                                    isSelectable
                                                                    key={address.id}
                                                                    active={address.id === billingAddress.addressId}
                                                                    address={address}
                                                                />
                                                            )) }
                                                            <AddAddressButton size='small' data-qa='button-accountAddresses-AddAddressButton' className={classes.buttonCheckout} />
                                                        </Collapse>
                                                    </>
                                                )
                                                : (
                                                    <div>
                                                        <ChangeAddressButton setAddress={onBillingAddressChanged} />
                                                    </div>
                                                )}
                                        </Grid>
                                    </Grid>
                                    <NavigationButtons
                                        isDisabledNext={!shippingAddress}
                                        onClickNext={onNext}
                                        dataQaNext='button-checkout-next'
                                    />
                                </Box>
                            </Grid>
                            <Grid item lg={3}>
                                <SideSummaryCart />
                            </Grid>
                        </Grid>
                        
                    </>
                )}
        </div>
    )
}

AddressSelection.propTypes = {
    onNext: func
}

AddressSelection.defaultProps = {
    onNext: undefined
}

export default AddressSelection
