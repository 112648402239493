import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    sideSummaryCart: {
        background: '#f1f1f1',
        height: '100%',
        padding: '25px 60px',
        '& h5': {
            color: 'black !important',
            fontWeight: 'bold',
        },
        '& div.MuiTypography-body1': {
            color: '#858585',
            padding: '8px 0'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none',
            background: 'transparent'
        },
        '& .MuiAccordion-root:before': {
            position: 'unset'
        },
        '& .MuiAccordionSummary-root': {
            padding: 0
        },
        '& .MuiAccordionDetails-root': {
            padding: 0,
            display: 'block'
        },
        [theme.breakpoints.down('lg')]: {
            padding: '25px 25px'
        }
    },
    customCard: {
        paddingBottom: '1rem',
        '& .MuiPaper-rounded': {
            borderRadius: '0px'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        '& .MuiCardMedia-img': {
            objectFit: 'contain'
        }
    },
    root: {
        display: 'flex',
        background: '#f1f1f1',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        maxWidth: '7rem'
    },
    boxContainer: {
        borderBottom: '1px solid #E7E7E7',
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    customAccordion: {
        '& .MuiAccordion-rounded:first-child': {
            borderRadius: '0'
        },
        '& .MuiAccordion-rounded:last-child': {
            borderRadius: '0'
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        }
    },
    sideSummaryCartMobile: {
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        background: 'black',
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        background: 'white',
        color: '#858585',
        '& $root': {
            background: 'white'
        },
        '& h5': {
            color: 'black !important',
            fontWeight: 'bold',
        },
        '& div.MuiTypography-body1': {
            color: '#858585',
            padding: '8px 0'
        },
    },
    floatRight: {
        float: 'right',
    },
    totalText: {
        fontWeight: 'bold',
        color: 'black',
        padding: '8px 0',
        fontSize: '16px'
    },
    titleSummary: {
        fontWeight: 'bold !important',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    accordionSideSummary: {
        '& .MuiAccordionDetails-root': {
            color: '#858585'
        }
    }
}))