import { useLogin } from '@bloomreach/connector-components-react'
import { BrPageContext } from '@bloomreach/react-sdk'
import React, { useContext, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { Container, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import clsx from 'clsx'
import LoginContext from './LoginContext'
import { LoyaltyPointsContext } from '../LoyaltyPoints/LoyaltyPointsContext'
import { usePageCfg } from '../../../_Elements/PageConfig/PageCfg'
import synchronizeLoyaltyPoints from '../LoyaltyPoints/synchronizeLoyaltyPoints'
import FormTextField from '../../../_Elements/Inputs/FormTextField'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import TextDivider from '../../../_Elements/TextDivider/TextDivider'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import { GoogleEnhancedEcommerce } from '../../ThirdPartyScripts/GoogleEnhancedEcommerce'
import { SFMCCollectJs } from '../../ThirdPartyScripts/SFMCCollectJs'
import { RETURN_URL_UNLOGGED_CHECKOUT } from '../../../_Mappings/urlPart'
import { COOKIE_LOCATION, COOKIE_LANGUAGE } from '../../../_Mappings/cookies'
import regionLocales from '../../../_Mappings/regionLocales'
import storeKeys from '../../../_Mappings/storeKeys'
import UserFeedbackDialog from '../UserFeedbackDialog/UserFeedbackDialog'
import LoginSocial from './LoginSocial'
import LoginUtils from './LoginUtils'
import Link from '../../../_Elements/Link'

const LoginForm = ({
    setShowForgotPassword
}) => {
    const classes = useStyles()
    const translations = useTranslation()
    const [cookies] = useCookies([COOKIE_LOCATION, COOKIE_LANGUAGE])
    const location = cookies[COOKIE_LOCATION]
    const language = cookies[COOKIE_LANGUAGE] ?? 'en'
    const [login, , loading, error] = useLogin()
    const page = useContext(BrPageContext)
    const { pushToDataLayer } = useContext(GoogleEnhancedEcommerce)
    const { pushUser, pushContactAttribute } = useContext(SFMCCollectJs)
    const { cartId, setCartId } = useContext(LoginContext)
    const { setLoyaltyPoints } = useContext(LoyaltyPointsContext)
    const { handleSubmit, ...formCtx } = useForm()
    const pageCfg = usePageCfg()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const { query, push } = useRouter()
    const isNeededForCheckout = query?.returnUrl === RETURN_URL_UNLOGGED_CHECKOUT
    const storeKey = storeKeys[location]?.storeKey
    
    const handleGuest = () => push({
        pathname: page.getUrl('/checkout'),
        query: {
            mergeWithExistingCustomerCart: false,
            guestCheckout: true
        }
    })

    const handleOnSubmit = handleSubmit(async ({
        username,
        password
    }) => {
        if (username && password) {
            try {
                const { customer, cart } = await login({
                    username,
                    password,
                    oldCartId: cartId,
                    mergeWithExistingCustomerCart: query?.mergeWithExistingCustomerCart ?? true,
                    country: location,
                    currency: storeKeys[location]?.currency
                })
                LoginUtils.checkCartWithAuth(cart?.id, username, password, storeKey, setCartId)
                logged(customer, cart)

            } catch (e) {
                console.error('Error: ', e)
            }
        }
    })

    const logged = async (customer, cart) => {
        if (customer) {
            setCartId?.(cart?.id)

            pushToDataLayer({
                event: 'login',
                eventCategory: 'Login',
                eventAction: 'loginSuccess',
                eventLabel: customer.id
            })

            pushUser(customer.email)

            const loyaltyPoints = await synchronizeLoyaltyPoints(customer, pageCfg)
            setLoyaltyPoints(loyaltyPoints)

            pushContactAttribute({
                email: customer.email,
                gender: customer.salutation,
                name: customer.firstName + ' ' + customer.lastName,
                loyaltyStatus: loyaltyPoints.customerGroup,
                language: language
            })
            if(!isNeededForCheckout){
                sessionStorage.setItem('show_welcome_popup', true)
            }
            push(page.getUrl(query.returnUrl ?? '/'))
        }
    }

    return (
        <>
        <Container maxWidth='md'>
            <Box display='flex' flexDirection='column' alignItems='center' className={classes.loginFormContainer}>
                <Typography
                    variant='h4'
                    component='h2'
                    className={classes.loginTitle}
                    data-qa='text-signin-login'
                >
                    {translations('login:login.title')}
                </Typography>

                {isNeededForCheckout && (
                    <Typography
                        variant='h5'
                        component='p'
                        className={classes.loginSubtitle}
                    >
                        {translations('login:login.needed.for.checkout')}
                    </Typography>
                )}
                <div className={classes.loginWrap}>
                    <div className={classes.halfWrap}>
                        <form onSubmit={handleOnSubmit} className={classes.form}>
                            <FormTextField
                                label={translations('forms:email.label')}
                                name='username'
                                formCtx={formCtx}
                                data-qa='input-signin-email'
                            />
                            <FormTextField
                                label={translations('forms:password.label')}
                                name='password'
                                type='password'
                                formCtx={formCtx}
                                data-qa='input-signin-password'
                            />
                            {error
                                && (
                                    <>
                                        <FormHelperText error>{error.message}</FormHelperText>
                                        <FormHelperText className={classes.serviceMessage}>
                                            {translations('login:login.service.message')}
                                        </FormHelperText>
                                    </>
                                )}
                            <div>
                                <Typography
                                    variant='body1'
                                    onClick={() => setShowForgotPassword(true)}
                                    className={classes.forgotPasswordLink}
                                    data-qa='link-signin-ForgotYourPassword'
                                >
                                    {translations('login:forgot.password.link')}
                                </Typography>
                            </div>
                            <Box justifyContent='center' className={clsx(isNeededForCheckout ? classes.column : classes.flex)}>
                                <BaseButton
                                    type='submit'
                                    variant='contained'
                                    loading={loading}
                                    data-qa='button-signin-login'
                                >
                                    {translations('login:login.button')}
                                </BaseButton>
                            </Box>
                            <div className={classes.flexElement}>
                                <Typography
                                    variant='body1'
                                >
                                    {translations('login:login.signin.text')}
                                </Typography>
                                <Typography
                                    variant='body1'
                                    className=''
                                >
                                    <Link href="signup">
                                        {translations('login:login.signin')}
                                    </Link>
                                </Typography>
                            </div>
                        </form>
                    </div>
                </div>
                <div className=''>
                    <TextDivider className={classes.textDividerPadding}>
                        {translations('login:login.socialLogin.logInwith')}
                    </TextDivider>
                    <Typography variant='subtitle1' component='h2' align='center'>
                        {translations('login:login.socialLogin')}
                    </Typography>
                    <LoginSocial />
                </div>
                {/* <div className={classes.registerContainer}>
                    <TextDivider>
                        {translations('login:create.account')}
                    </TextDivider>
                    <BaseButton
                        variant='outlined'
                        href='/signup'
                        data-qa='button-signin-register'
                        component={Link}
                    >
                        {translations('login:register.button')}
                    </BaseButton>
                </div> */}
            </Box>
            <UserFeedbackDialog
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                handleAfterClose={() => { }}
            >
                <Typography variant='body1'>
                    {translations('login:come.back.to.login.page')}
                </Typography>
            </UserFeedbackDialog>
        </Container>

        {isNeededForCheckout && (
            <Box className={classes.orderAsGuestContainer}>
                <Typography
                    variant='h4'
                    component='h2'
                    className={classes.loginTitle}
                    data-qa='text-signin-login'
                >
                    {translations('login:login.unauthenticated.title')}
                </Typography>

                <Typography
                    variant='h5'
                    component='p'
                    className={classes.loginSubtitle}
                >
                    {translations('login:login.unauthenticated.subtitle')}
                </Typography>

                <BaseButton
                    variant='outlined'
                    onClick={handleGuest}
                    data-qa='button-signin-guest'
                >
                    {translations('login:guest')}
                </BaseButton>
                
            </Box>
        )}
        </>
    )
}


LoginForm.defaultProps = {
    setShowForgotPassword: undefined
}

export default LoginForm
