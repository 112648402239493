import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    formControl: {
        maxWidth: theme.breakpoints.values.s,
        display: 'flex',
        alignItems: 'center'
    },
    radioGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formControlLabel: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            display: 'none'
        }
    },
    sizeGuide: {
        marginRight: theme.spacing(2)
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sizeTable: {
        '& table': {
            width: '100% !important',
            marginBottom: theme.spacing(5)
        }
    },
    smallText: {
        fontSize: 12,
        marginBottom: theme.spacing(1.5),
        lineHeight: '15.6px',
        letterSpacing: '0.05em',
        color: theme.palette.primary.main,
        fontFamily: 'DM Sans',
        alignItems: 'center'
    }
}))
