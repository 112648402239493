import {
    object, func, shape, string
} from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useCookies } from 'react-cookie'
import Typography from '@material-ui/core/Typography'
import { Box, Checkbox, FormControlLabel, FormHelperText, Grid } from '@material-ui/core'
import useStyles from './style'
import useTranslation from '../../../../../_Elements/ResourceBundles/useTranslation'
import CheckOutContext from '../../../CheckOutContext'
import { usePageCfg } from '../../../../../_Elements/PageConfig/PageCfg'
import CartItem from '../../../../Cart/CartItem/CartItem'
import LoginContext from '../../../../Account/Login/LoginContext'
import Discount from '../../../../Cart/Discount/Discount'
import { COOKIE_LANGUAGE, COOKIE_LOCALE, COOKIE_LOCATION } from '../../../../../_Mappings/cookies'
import storeKeys from '../../../../../_Mappings/storeKeys'
import useCustomCart from '../../../../../_Hooks/useCustomCart/useCustomCart'
import NavigationButtons from '../NavigationButtons/NavigationButtons'
import Address from './AddressSelection/Address/Address'
import GiftBox from '../GiftBox/GiftBox'
import useCustomCheckout from '../../../../../_Hooks/useCustomCheckout/useCustomCheckout'
import useCustomSetOrderDetails from '../../../../../_Hooks/useCustomCheckout/useCustomSetOrderDetails'
import UserFeedbackDialog from '../../../../Account/UserFeedbackDialog/UserFeedbackDialog'
import { useRouter } from 'next/router'



const Review = ({
    page, user, paymentInfos, onBack, onNext
}) => {
    const classes = useStyles()
    const translations = useTranslation()
    const { orderDraft, setOrderDraft, shippingAddress, billingAddress } = useContext(CheckOutContext)
    const pageCfg = usePageCfg()

    const [isLoading, setIsLoading] = useState(false)
    const [areConditionsChecked, setAreConditionsChecked] = useState(false)
    const [firstSubmit, setFirstSubmit] = useState(false)

    const [checkOut, { error }] = useCustomCheckout()
    // Store
    const [cookies] = useCookies([COOKIE_LOCALE, COOKIE_LOCATION, COOKIE_LANGUAGE])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const language = cookies[COOKIE_LANGUAGE] ?? 'en'
    
    const [setOrderDetails] = useCustomSetOrderDetails()
    const { storeKey } = storeKeys[location] || {}

    const { cartId } = useContext(LoginContext)
    const { cart } = useCustomCart()
    const { query, replace } = useRouter()
    // UserFeedbackModal
    const [isDialogOpen, setIsDialogOpen] = useState(!!query.status)

    const onClick = async () => {
        setFirstSubmit(true)
        if(areConditionsChecked){
            setIsLoading(true)
            try{
                const { data } = await checkOut()
    
                if (data?.checkOut?.code === '200' && data?.checkOut?.order) {
                    setOrderDraft(data.checkOut.order)
                }
                const input = {id: cartId }
                if (user?.anonymous) {
                    input.billingAddress = { address: billingAddress.address },
                    input.shipmentAddress = { address: shippingAddress.address }
                    input.email = shippingAddress.email
                }
                else {
                    input.billingAddress = { addressId: billingAddress.addressId },
                    input.shipmentAddress = { addressId: shippingAddress.addressId }
                    input.email = user.currentCustomer.email
                }
    
                const result = await setOrderDetails({
                    variables: { input }
                })
    
                if (setOrderDraft && result?.data?.setOrderDetails?.order) {
                    setOrderDraft((od) => ({
                        ...od,
                        ...result?.data?.setOrderDetails?.order
                    }))
                }
                setIsLoading(false)
                onNext()
            } catch(e){
                console.error(e)
                setIsLoading(false)
            }
        }
    }

    if (!cart || !shippingAddress) {
        return (
            <div className={classes.reviewAddressesContainer}>
                <CircularProgress />
            </div>
        )
    }

    if (error) {
        return <div>{error.message}</div>
    }

    return (
        <>
            <NavigationButtons
                onClickBack={onBack}
                dataQaBack='button-review-back'
            />

            <Box className={classes.header}>
                <Typography
                    variant='h6'
                    align='center'
                    className={classes.titleStyle}
                >
                    {translations('checkout:step.header.review')}
                </Typography>
                <Typography variant="body1" align='center'>
                    {translations('checkout:step.header.review.subtitle')}
                </Typography>
            </Box>
            
            <div className={classes.reviewAddressesContainer}>

                <List aria-label='cart product list' className={classes.itemList}>
                    {
                        cart?.entries?.filter(Boolean).map((entry, index) => (
                            <CartItem
                                key={entry.id}
                                entry={entry}
                                cartId={cart.id}
                                position={index}
                            />
                        ))
                    }
                </List>

                <Grid
                    container
                    spacing={4}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        direction='column'
                        container
                    >
                        <Typography
                            variant='h6'
                            className={classes.fontWeightBold}
                        >
                            {translations('address:shipping.address.title')}
                        </Typography>
                        <Address active address={shippingAddress?.address} className={classes.addressesReview} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        direction='column'
                        container
                    >
                        <Typography
                            variant='h6'
                            className={classes.fontWeightBold}
                        >
                            { translations('address:billing.address.title') }
                        </Typography>
                        <Address active address={billingAddress?.address} className={classes.addressesReview} />
                    </Grid>
                </Grid>

                <Discount cartId={cartId} storeKey={storeKey} className={classes.reviewSummaryContainer} />

                {/* <GiftBox className={classes.reviewSummaryContainer}  />*/}

                <div className={classes.summaryContainer}>
                    {/* <ChosenPayment paymentInfos={paymentInfos} /> */}
                    {/* <SubtotalCard totalListPrice={cart?.totalListPrice.moneyAmounts[0]} className={classes.reviewSummaryContainer} /> */}
                    {/* <Discount cartId={cartId} storeKey={storeKey} className={classes.reviewSummaryContainer} readOnly/> */}
                    {/* <PriceSummary
                        shipAmount={orderDraft.shipAmount}
                        totalListPrice={cart?.totalListPrice.moneyAmounts[0]}
                        totalPurchasePrice={cart?.totalPurchasePrice.moneyAmounts[0]}
                        totalQuantity={cart?.totalQuantity}
                        discounts={cart.discounts}
                        className={classes.reviewSummaryContainer}
                    /> */}
                    <div className={classes.summaryReview}>
                        <div>
                            <Typography variant='body1' className={classes.summarySubtotal}>
                                {translations('checkout:subtotal')}
                            </Typography>
                            {cart.discounts.length > 0 && (
                                <Typography variant='body1' className={classes.summarySubtotal}>
                                    {translations('checkout:discount.label')}
                                </Typography>
                            )}

                            {/* SHIPPING */}
                            <Typography variant='body1' style={{ wordBreak: 'nowrap' }}>
                                {translations('checkout:shipping')}
                            </Typography>
                        </div>
                        <div className={classes.textAlignRight}>
                            <Typography variant='subtitle2'>
                                { cart?.totalListPrice.moneyAmounts[0]?.displayValue }
                            </Typography>

                            {cart.discounts.length > 0  && (
                                <Typography variant='subtitle2'>
                                    {cart?.totalPurchasePrice.moneyAmounts[0].currency}
                                    {' '}
                                    {(cart?.totalPurchasePrice.moneyAmounts[0]?.amount - cart?.totalListPrice.moneyAmounts[0]?.amount - (orderDraft?.shipAmount?.amount ?? 0)).toFixed(2)}
                                </Typography>
                            )}

                            {/* SHIPPING */}
                            {orderDraft?.shipAmount?.displayValue || (cart.discounts.length === 0 && cart?.totalPurchasePrice.moneyAmounts[0].amount !== cart?.totalListPrice.moneyAmounts[0].amount)
                                ? (
                                    <Typography variant='subtitle2'>
                                        { orderDraft?.shipAmount?.displayValue ?? `${cart?.totalPurchasePrice.moneyAmounts[0]?.currency} ${(cart?.totalPurchasePrice.moneyAmounts[0].amount - cart?.totalListPrice.moneyAmounts[0].amount).toFixed(2)} `}
                                    </Typography>
                                )
                                : (
                                    <Typography variant='body1'>
                                        {translations('checkout:shipping.cost.requirement')}
                                    </Typography>
                                )
                            }
                        </div>
                    </div>

                    <div className={classes.summaryReview}>
                        <div>
                            <Typography variant='body1' className={classes.fontWeightBold}>
                                {translations('checkout:grand.total')}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant='subtitle2' className={classes.fontWeightBold}>
                                {`${cart?.totalPurchasePrice.moneyAmounts[0]?.currency} ${(+cart?.totalPurchasePrice.moneyAmounts[0]?.amount).toFixed(2)}`}
                            </Typography>
                        </div>
                    </div>

                </div>
                
                    { location === 'GB' &&
                    <div className={classes.summaryReview}>
                        <Typography variant='body1'>
                            {translations('checkout:alert.uk')}
                        </Typography>
                    </div>
                    }
                    { (cart?.totalPurchasePrice.moneyAmounts[0]?.amount).toFixed(2) > 799.99 && location === 'US' &&
                    <div className={classes.summaryReview}>
                        <Typography variant='body1'>
                            {translations('checkout:alert.us')}
                        </Typography> 
                    </div>
                    }
                

                <Box className={classes.conditionsSale}>
                    <FormControlLabel
                        label={translations('checkout:review.checkbox')}
                        control={(
                            <Checkbox
                                className={''}
                                color='primary'
                                onChange={() => setAreConditionsChecked(!areConditionsChecked)}
                            />
                        )}
                    />
                    {!areConditionsChecked && firstSubmit && (
                        <FormHelperText error={true}>{translations('checkout:review.checkbox.error')}</FormHelperText>
                    )}
                </Box>

                <NavigationButtons
                    onClickNext={onClick}
                    loading={isLoading}
                    dataQaNext='button-review-next'
                />
            </div>
            {query.status && (
                <UserFeedbackDialog
                    open={isDialogOpen}
                    setOpen={setIsDialogOpen}
                    handleAfterClose={() => replace('checkout?checkoutStep=2', undefined, { shallow: true })}
                >
                    <Typography variant='body1'>
                        {translations('checkout:error.paymentDeclined')}
                    </Typography>
                </UserFeedbackDialog>
            )}
        </>
    )
}

Review.propTypes = {
    page: object,
    user: object,
    paymentInfos: shape({
        encryptedPayment: string,
        paymentMethodCategory: string,
        paymentLabel: string,
        paymentLogo: string
    }),
    onBack: func
}

Review.defaultProps = {
    page: undefined,
    user: null,
    paymentInfos: {
        encryptedPayment: '',
        paymentMethodCategory: '',
        paymentLabel: '',
        paymentLogo: ''
    },
    onBack: () => undefined
}

export default Review
