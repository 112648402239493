import React, {
    useState, useEffect, useContext, useMemo, useLayoutEffect
} from 'react'
import { CircularProgress, Container, Stepper } from '@material-ui/core'
import { func, shape } from 'prop-types'
import { useAddressGroup } from '@bloomreach/connector-components-react'
import { useRouter } from 'next/router'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import BaldininiLogo from '../../../../public/Baldinini-Logo.svg'
import CheckOutContext from '../CheckOutContext'
import LoginContext from '../../Account/Login/LoginContext'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import StepContent from './components/StepContent/StepContent'
import Link from '../../../_Elements/Link'
import useCustomCart from '../../../_Hooks/useCustomCart/useCustomCart'
import { RETURN_URL_UNLOGGED_CHECKOUT } from '../../../_Mappings/urlPart'
import { isMobileOrTablet } from '../../../../lib/utils'

// const StepContent = dynamic(
//     () => import('./components/StepContent/StepContent'),
//     { ssr: false }
// )

const connector = 'commercetools'
const CheckOut = ({ page }) => {
    const {
        user,
        loading: userLoading,
        cartId
    } = useContext(LoginContext)
    const [addressGroup] = useAddressGroup()

    const [orderDraft, setOrderDraft] = useState()
    const [shippingAddress, setShippingAddress] = useState(() => {
        const storedSA = sessionStorage.getItem('shippingAddress')
        return storedSA ? JSON.parse(storedSA) : {}
      })
    const [billingAddress, setBillingAddress] = useState(() => {
        const storedBA = sessionStorage.getItem('billingAddress')
        return storedBA ? JSON.parse(storedBA) : {}
      })
    const [orderId, setOrderId] = useState()
    const router = useRouter()

    const classes = useStyles()
    const translations = useTranslation()
    const { refetch } = useCustomCart()

    const steps = useMemo(() => [
        { id: 'addresses', label: translations('checkout:addresses') },
        { id: 'review', label: translations('checkout:review') },
        { id: 'payment', label: translations('checkout:payment') },
        { id: 'done', label: translations('checkout:done') }
    ].filter(Boolean), [translations])

    const { query: { checkoutStep = '1' } } = useRouter()
    const checkoutStepInt = +checkoutStep
    const [activeStep, setActiveStep] = useState(checkoutStepInt === 3 ? 2 : checkoutStepInt)

    const handleNext = async () => {
        await refetch()
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = async () => {
        await refetch()
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    useEffect(() => {
        const { path, ...query } = router.query

        if (+checkoutStep !== +activeStep) {
            router.push({
                pathname: '/checkout',
                query: {
                    ...query,
                    checkoutStep: encodeURIComponent(activeStep)
                }
            })
        }
    }, [activeStep])

    useEffect(() => {
        if (+checkoutStep !== +activeStep) {
            setActiveStep(+checkoutStep)
        }
    }, [checkoutStep])

    useLayoutEffect(() => {
        const parent = document.getElementById('MuiStepper-horizontal')

        steps.map((step, index) => {
            console.log("index", step, index)
            if(index === (activeStep - 1)){
                let currentItem = document.getElementById(step.id)
                console.log("SPOSTO", currentItem?.getBoundingClientRect()?.left)
                if(parent && window.innerWidth < 768){
                    console.log("parent.style.marginLeft", parseFloat(parent.style.marginLeft.substring(0, parent.style.marginLeft.length-2)), (currentItem?.getBoundingClientRect()?.left / 2))
                    if(activeStep === 1){
                        parent.style.marginLeft =  0 + "px"
                    }else{
                        parent.style.marginLeft =  - (Math.abs(parseFloat(parent.style.marginLeft.substring(0, parent.style.marginLeft.length-2))) + (currentItem?.getBoundingClientRect()?.left / 2)) + "px"
                    }
                }
            }
        })
    }, [activeStep])

    useEffect(() => {
        sessionStorage.setItem('billingAddress', JSON.stringify(billingAddress));
    }, [billingAddress])

    useEffect(() => {
        sessionStorage.setItem('shippingAddress', JSON.stringify(shippingAddress));
    }, [shippingAddress])
        
    if (!user || userLoading) {
        return <CircularProgress />
    }

    if (!cartId && !orderDraft) {
        return (
            <div>
                {translations('checkout:message.add.items')}
            </div>
        )
    }

    return (
        <>
            {/* <Container maxWidth='lg'> */}
                <CheckOutContext.Provider value={{
                    orderDraft,
                    setOrderDraft,
                    connector,
                    orderId,
                    setOrderId,
                    shippingAddress,
                    setShippingAddress,
                    billingAddress,
                    setBillingAddress
                }}
                >
                    <Link
                        color='inherit'
                        href={page.getUrl('/')}
                        className={classes.logoContainer}
                    >
                        <BaldininiLogo />
                    </Link>
                    <Stepper id="MuiStepper-horizontal" activeStep={activeStep - 1} nonLinear={isMobileOrTablet()} alternativeLabel={!isMobileOrTablet()} className={classes.customStepper}>
                        {steps.map(({ id, label }) => (
                            <Step key={id} id={id}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className={classes.root}>
                        <div>
                            <div>
                                <div className={classes.instructions}>
                                    <StepContent
                                        page={page}
                                        activeStep={activeStep}
                                        addressGroup={addressGroup}
                                        user={user}
                                        classes={classes}
                                        onNext={handleNext}
                                        onBack={handleBack}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </CheckOutContext.Provider>
            {/* </Container> */}
        </>
    )
}

const propTypes = {
    page: shape({
        getButton: func,
        getChannelParameters: func,
        getComponent: func,
        getContent: func,
        getDocument: func,
        getMeta: func,
        getTitle: func,
        getUrl: func,
        getVersion: func,
        getVisitor: func,
        getVisit: func,
        isPreview: func,
        rewriteLinks: func,
        sync: func,
        toJSON: func
    })
}

const defaultProps = {
    page: undefined
}

const CheckoutProxy = (props) => {
    const { user } = useContext(LoginContext)
    const { page } = props
    const router = useRouter()
    const isGuestCheckout = router?.query?.guestCheckout

    const [renderCheckout, setRenderCheckout] = useState(false)

    useEffect(() => {
        if (user?.anonymous && !isGuestCheckout) {
            router.push({
                pathname: page.getUrl('/signin'),
                query: {
                    mergeWithExistingCustomerCart: false,
                    returnUrl: RETURN_URL_UNLOGGED_CHECKOUT
                }
            })
        } else {
            setRenderCheckout(true)
        }
    }, [user?.anonymous])

    if (!renderCheckout) {
        return null
    }

    return (
        <CheckOut {...props} />
    )
}

CheckOut.propTypes = propTypes
CheckOut.defaultProps = defaultProps
CheckoutProxy.propTypes = propTypes
CheckoutProxy.defaultProps = defaultProps

export default CheckoutProxy
