import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    location: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        order: 1,
        marginBottom: theme.spacing()
    },

    active: {
        backgroundColor: theme.palette.gray.lighter,
        order: 0
    }
}))
