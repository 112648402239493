import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => ({
    logoContainer: {
        width: '100%',
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addressesContainer: {
        // maxWidth: theme.breakpoints.values.md,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        margin: theme.spacing(0, 'auto'),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    addAddressContainer: {
        maxWidth: theme.breakpoints.values.sm,
        margin: theme.spacing(4, 'auto')
    },
    root: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            // last button visible
            height: 'calc(100% + 70px)',
        }
    },
    instructions: {
        // marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(1)
    },
    customStepper: {
        background: '#f8f8f8',
        [theme.breakpoints.down('lg')]: {
            overflow: 'hidden'
        },
        '& .MuiStepLabel-label': {
            [theme.breakpoints.down('lg')]: {
                whiteSpace: 'nowrap'
            },
        },
        '& .MuiStepIcon-root': {
            width: '2em',
            height: '2em',
            [theme.breakpoints.down('lg')]: {
                width: '1em',
                height: '1em',
            },
        },
        '& .MuiStepConnector-alternativeLabel': {
            top: '24px',
            left: 'calc(-50% + 45px)',
            right: 'calc(50% + 45px)',
        },
        '& .MuiStepConnector-lineHorizontal': {
            [theme.breakpoints.down('md')]: {
                width: '25px'
            },
        }
    },
}))
